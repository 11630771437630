export const iconProps = [
    {
        name: "iconLeft",
        type: "icon",
        group: "icon",
    },
    {
        name: "iconRight",
        type: "icon",
        group: "icon",
    },
] as const;
