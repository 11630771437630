import React, { createContext, useContext, useEffect, useId, useRef, useState } from "react";
import { registerComponent } from "@/utils";
import { useMergeRefs } from "@/utils/merge-refs";
import {
    classProp,
    marginProp,
    sizes,
    styleProp,
    useInlineEditInputParts,
    useInlineEditLabel,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import useEvent from "@/utils/use-event";
import { useActions } from "@anvil-works/anvil-react";
import { inDesigner, useDesignerApi, useRegionInteractionRef } from "@anvil-works/anvil-react/designer";
import { Radio, RadioGroup } from "@mantine/core";

interface RadioGroupContextValue {
    value: string;
    forceChange: (target: { value: string; checked: boolean }) => void;
}
const RadioGroupContext = createContext<RadioGroupContextValue | null>(null);
export const RadioGroupProvider = RadioGroupContext.Provider;
export const useRadioGroupContext = () => useContext(RadioGroupContext);

registerComponent({
    name: "Radio",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        { name: "variant", type: "enum", options: ["filled", "outline"], defaultValue: "filled", important: true },

        {
            name: "autoContrast",
            type: "boolean",
            description:
                "Determines whether icon color with filled variant should depend on background-color. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast.",
            group: "appearance",
        },
        {
            name: "color",
            type: "color",
            description:
                "Key of theme.colors or any valid CSS color to set input color in checked state, theme.primaryColor by default",
            group: "appearance",
        },
        {
            name: "description",
            type: "string",
            description: "Description displayed below the label",
            group: "interaction",
        },
        {
            name: "error",
            type: "string",
            description: "Error displayed below the label",
            group: "interaction",
        },
        // {
        //     name: "icon",
        //     type: "FC<RadioIconProps>",
        //     description: "A component that replaces default check icon",
        //     group: "icon",
        // },
        {
            name: "iconColor",
            type: "color",
            description:
                "Key of theme.colors or any valid CSS color to set icon color, by default value depends on theme.autoContrast",
            group: "appearance",
        },
        {
            name: "label",
            type: "string",
            description: "Content of the label associated with the radio",
            important: true,
        },
        {
            name: "labelPosition",
            type: "enum",
            options: ["left", "right"],
            description: "Position of the label relative to the input",
            defaultValue: "right",
            important: true,
        },
        {
            name: "radius",
            type: "enum",
            options: sizes,
            description: "Key of theme.radius or any valid CSS value to set border-radius",
            defaultValue: "xl",
            group: "appearance",
        },
        // {
        //     name: "rootRef",
        //     type: "ForwardedRef<HTMLDivElement>",
        //     description: "Assigns ref of the root element",
        //     group: "ref",
        // },
        {
            name: "size",
            type: "enum",
            options: sizes,
            description: "Controls size of the component",
            defaultValue: "sm",
            group: "appearance",
        },
        {
            name: "value",
            type: "string",
            description: "Controlled component value",
            important: true,
            priority: 10,
        },
        {
            name: "checked",
            type: "boolean",
            defaultValue: false,
            description: "",
            important: true,
            priority: 10,
            supportsWriteback: true,
            defaultBindingProp: true,
            designerHint: "toggle",
        },
        {
            name: "disabled",
            type: "boolean",
            group: "interaction",
            description: "",
            designerHint: "disabled",
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    component({ properties: { checked, value, visible, style, className, margin, ...props } }, ref) {
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();

        const ctx = useRadioGroupContext();
        checked ??= false;
        // if the child changes checked
        // then we need to fire
        const [prevChecked, setPrevChecked] = useState(checked);

        const updateChecked = async (checked: boolean) => {
            setPrevChecked(checked);
            setProperty("checked", checked);
            try {
                await triggerWriteBack("checked", checked);
            } finally {
                raiseEvent("change");
            }
        };

        const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
            updateChecked(e.target.checked);
        };

        if (ctx) {
            const groupChecked = ctx.value === value;
            if (groupChecked !== checked && value) {
                // then our group has changed our value and our group wins
                if (prevChecked === checked) {
                    queueMicrotask(() => {
                        updateChecked(groupChecked);
                    });
                } else {
                    // we have changed our own checked value tell context to update
                    queueMicrotask(() => {
                        setPrevChecked(checked);
                        ctx.forceChange({ value, checked });
                    });
                }
            } else if (prevChecked !== checked) {
                setPrevChecked(checked);
            }
        } else if (prevChecked !== checked) {
            setPrevChecked(checked);
        }

        ref = useVisibleProp(ref, visible);
        const inputRef = useRegionInteractionRef<HTMLInputElement>(() => {
            setProperty("checked", !checked);
        });
        const inputParts = useInlineEditInputParts(props);

        return (
            <Radio
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                ref={inputRef}
                checked={!!checked}
                value={value}
                onChange={onChange}
                rootRef={ref}
                {...props}
                {...inputParts}
                {...useMarginStyles(margin)}
            />
        );
    },
});

registerComponent({
    name: "RadioGroup",
    container: true,
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        {
            name: "description",
            type: "string",
            description: "Contents of Input.Description component. If not set, description is not rendered.",
            group: "interaction",
        },
        {
            name: "error",
            type: "string",
            description: "Contents of Input.Error component. If not set, error is not rendered.",
            group: "interaction",
        },
        // {
        //     name: "errorProps",
        //     type: "Record<string, any>",
        //     description: "Props passed down to the Input.Error component",
        //     group: "error",
        // },
        // {
        //     name: "id",
        //     type: "string",
        //     description: "Static id used as base to generate aria- attributes, by default generates random id",
        //     group: "id",
        // },
        // {
        //     name: "inputContainer",
        //     type: "((children: ReactNode) => ReactNode)",
        //     description: "Input container component, React.Fragment by default",
        //     defaultValue: "React.Fragment",
        //     group: "container",
        // },
        // {
        //     name: "inputWrapperOrder",
        //     type: "enum",
        //     options: ["input", "label", "description", "error"],
        //     description: "Controls order of the elements",
        //     defaultValue: ["label", "description", "input", "error"],
        //     group: "layout",
        // },
        {
            name: "label",
            type: "string",
            description: "Contents of Input.Label component. If not set, label is not rendered.",
            important: true,
        },
        // {
        //     name: "labelElement",
        //     type: "enum",
        //     options: ["div", "label"],
        //     description: "Input.Label root element",
        //     defaultValue: "label",
        //     group: "label",
        // },
        // {
        //     name: "labelProps",
        //     type: "Record<string, any>",
        //     description: "Props passed down to the Input.Label component",
        //     group: "label",
        // },
        // {
        //     name: "name",
        //     type: "string",
        //     description: "name attribute of child radio inputs. By default, name is generated randomly.",
        //     group: "input",
        // },
        // {
        //     name: "onChange",
        //     type: "((value: string) => void)",
        //     description: "Called when value changes",
        //     group: "event",
        // },
        {
            name: "readOnly",
            type: "boolean",
            description: "If set, value cannot be changed",
            group: "interaction",
        },
        {
            name: "required",
            type: "boolean",
            description:
                "Adds required attribute to the input and a red asterisk on the right side of label, false by default",
            defaultValue: false,
            group: "interaction",
        },
        {
            name: "size",
            type: "enum",
            options: sizes,
            description: "Controls size of the Input.Wrapper",
            defaultValue: "sm",
            group: "appearance",
        },

        {
            name: "withAsterisk",
            type: "boolean",
            description:
                "Determines whether the required asterisk should be displayed. Overrides required prop. Does not add required attribute to the input.",
            defaultValue: false,
            group: "interaction",
        },
        {
            name: "value",
            type: "string",
            description: "Controlled component value",
            important: true,
            priority: 10,
            defaultBindingProp: true,
            supportsWriteback: true,
        },
        // {
        //     name: "wrapperProps",
        //     type: "Record<string, any>",
        //     description: "Props passed down to the Input.Wrapper",
        //     group: "wrapper",
        // },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    layoutProperties: [],
    // autoDropZones: false,
    component({ children, properties: { visible, value, style, className, margin, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        value ??= "";
        const groupRef = useRef<HTMLElement>(null);
        const id = useId();

        const { triggerWriteBack, setProperty, raiseEvent } = useActions();

        const onChange = useEvent(async (value: string) => {
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("change");
            }
        });

        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);
        if (inDesigner) {
            styleObject.minHeight = 20;
        }

        const inputParts = useInlineEditInputParts(props);

        return (
            <RadioGroup
                name={id}
                value={value}
                onChange={onChange}
                ref={useMergeRefs(ref, groupRef)}
                styles={{ root: styleObject }}
                classNames={{ root: className }}
                {...props}
                {...inputParts}
                {...marginStyles}>
                <RadioGroupProvider
                    value={{
                        value,
                        forceChange(target) {
                            let newValue = value;
                            if (target.checked) {
                                newValue = target.value;
                            } else if (value === target.value) {
                                newValue = "";
                            }
                            if (newValue !== value) {
                                onChange(newValue);
                            }
                        },
                    }}>
                    {children}
                </RadioGroupProvider>
            </RadioGroup>
        );
    },
});
