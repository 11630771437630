import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    sizes,
    styleProp,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { DropZone, inDesigner } from "@anvil-works/anvil-react/designer";
import { Avatar, AvatarGroup } from "@mantine/core";

registerComponent({
    name: "AvatarGroup",
    container: true,
    properties: [
        {
            name: "spacing",
            type: "enum",
            options: sizes,
            defaultValue: "sm",
            group: "spacing",
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    layoutProperties: [],
    component(
        { children, childrenWithoutDropZones, properties: { visible, icon, text, style, className, margin, ...props } },
        ref
    ) {
        ref = useVisibleProp(ref, visible);
        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);
        if (inDesigner) {
            styleObject.minHeight = 20;
        }
        const hasChildren = !!childrenWithoutDropZones.length;
        return (
            <AvatarGroup
                styles={{ group: styleObject }}
                classNames={{ group: className }}
                ref={ref}
                {...props}
                {...marginStyles}>
                {hasChildren ? (
                    children
                ) : (
                    <DropZone style={{ flexGrow: 1, height: 0 }} minChildIdx={0} maxChildIdx={0} />
                )}
            </AvatarGroup>
        );
    },
});

registerComponent({
    name: "Avatar",
    properties: [
        {
            name: "autoContrast",
            type: "boolean",
            group: "appearance",
            description:
                "Determines whether button text color with filled variant should depend on background-color. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast.",
        },
        {
            name: "color",
            type: "color",
            group: "appearance",
            description: "Key of theme.colors or any valid CSS color, theme.primaryColor by default",
        },
        {
            name: "radius",
            type: "enum",
            options: sizes,
            description: "Key of theme.radius or any valid CSS value to set border-radius, 0 by default",
            defaultValue: "xl",
            group: "appearance",
        },
        {
            name: "size",
            type: "enum",
            options: sizes,
            defaultValue: "md",
            group: "appearance",
            description: "Controls input height and horizontal padding, 'sm' by default",
        },
        {
            name: "text",
            type: "string",
            important: true,
        },
        {
            name: "icon",
            type: "icon",
            important: true,
        },
        {
            name: "alt",
            type: "string",
            important: true,
        },

        {
            name: "src",
            type: "uri",
            accept: "image/*",
            description: "Image url",
            important: true,
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    layoutProperties: [],
    component({ properties: { visible, style, icon, text, className, margin, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        let children: React.ReactNode = null;
        if (icon) {
            children = makeIcon(icon);
        } else if (text) {
            children = text;
        }
        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);
        return (
            <Avatar
                ref={ref}
                styles={{ root: styleObject }}
                classNames={{ root: className }}
                {...props}
                {...marginStyles}>
                {children}
            </Avatar>
        );
    },
});
