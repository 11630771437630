import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import { TEXT_INPUT_EVENTS, useTextInputEvents } from "@/utils/events";
import {
    disabledProp,
    iconProps,
    inputInteractionProps,
    inputPartProps,
    inputPointerProp,
    marginProp,
    placeholderProp,
    radiusProp,
    sizeProp,
    styleProps,
    useInlineEditInputParts,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    variant,
    visibleProp,
    withErrorStyles,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { NumberInput } from "@mantine/core";
import { defaultInputVariant } from "./pin-input";
import { useWritebackValueOnNativeChange } from "./utils";

registerComponent({
    name: "NumberInput",
    events: TEXT_INPUT_EVENTS,
    properties: [
        defaultInputVariant,
        {
            name: "allowDecimal",
            type: "boolean",
            description: "Determines whether decimal values are allowed",
            defaultValue: true,
            group: "validation",
        },
        {
            name: "allowLeadingZeros",
            type: "boolean",
            description:
                "Determines whether leading zeros are allowed. If not set, leading zeros are removed when the input is blurred.",
            defaultValue: false,
            group: "validation",
        },
        {
            name: "allowNegative",
            type: "boolean",
            description: "Determines whether negative values are allowed",
            defaultValue: true,
            group: "validation",
        },
        {
            name: "allowedDecimalSeparators",
            type: "text[]",
            description: "Characters which when pressed result in a decimal separator",
            defaultValue: ["."],
            group: "validation",
        },
        {
            name: "clampBehavior",
            type: "enum",
            options: ["none", "blur", "strict"],
            defaultValue: "none",
            description:
                "Controls how value is clamped, strict – user is not allowed to enter values that are not in [min, max] range, blur – user is allowed to enter any values, but the value is clamped when the input loses focus (default behavior), none – lifts all restrictions, [min, max] range is applied only for controls and up/down keys",
            group: "behavior",
        },
        {
            name: "decimalScale",
            type: "number",
            description: "Limits the number of digits that can be entered after the decimal point",
            group: "formatting",
        },
        {
            name: "decimalSeparator",
            type: "string",
            description: "Character used as a decimal separator",
            defaultValue: ".",
            group: "formatting",
        },
        ...inputPartProps,
        ...iconProps,
        disabledProp,
        {
            name: "fixedDecimalScale",
            type: "boolean",
            description: "If set, 0s are added after decimalSeparator to match given decimalScale.",
            defaultValue: false,
            group: "formatting",
        },
        // {
        //     name: "handlersRef",
        //     type: "ForwardedRef<NumberInputHandlers> | undefined",
        //     description: "Increment/decrement handlers",
        //     group: "ref",
        // },
        {
            name: "hideControls",
            type: "boolean",
            description: "Determines whether the up/down controls should be hidden",
            defaultValue: false,
            group: "behavior",
        },
        // {
        //     name: "inputContainer",
        //     type: "((children: ReactNode) => ReactNode)",
        //     description: "Input container component, React.Fragment by default",
        //     defaultValue: "React.Fragment",
        //     group: "container",
        // },
        // {
        //     name: "inputWrapperOrder",
        //     type: "enum",
        //     options: ["input", "label", "description", "error"],
        //     description: "Controls order of the elements",
        //     defaultValue: ["label", "description", "input", "error"],
        //     group: "layout",
        // },
        {
            name: "isAllowed",
            type: "object",
            description:
                "A function to validate the input value. If this function returns false, the onChange will not be called and the input value will not change.",
            group: "validation",
        },

        {
            name: "max",
            type: "number",
            description: "Maximum possible value",
            group: "number",
        },
        {
            name: "min",
            type: "number",
            description: "Minimum possible value",
            group: "number",
        },
        placeholderProp,
        ...inputInteractionProps,
        {
            name: "prefix",
            type: "string",
            description: "Prefix added before the input value",
            group: "formatting",
        },
        radiusProp,
        // {
        //     name: "rightSectionPointerEvents",
        //     type: "React.CSSProperties['pointerEvents']",
        //     description: "Sets pointer-events styles on the rightSection element",
        //     defaultValue: "none",
        //     group: "section",
        // },
        // {
        //     name: "rightSectionProps",
        //     type: "React.ComponentPropsWithoutRef<'div'>",
        //     description: "Props passed down to the rightSection element",
        //     group: "section",
        // },
        // {
        //     name: "rightSectionWidth",
        //     type: "React.CSSProperties['width']",
        //     description: "Right section width, used to set width of the section and input padding-right",
        //     group: "section",
        // },
        sizeProp,
        {
            name: "startValue",
            type: "number",
            description:
                "Value set to the input when increment/decrement buttons are clicked or up/down arrows pressed if the input is empty",
            defaultValue: 0,
            group: "behavior",
        },
        {
            name: "step",
            type: "number",
            description:
                "Number by which value will be incremented/decremented with up/down controls and keyboard arrows",
            defaultValue: 1,
            group: "number",
        },
        {
            name: "stepHoldDelay",
            type: "number",
            description: "Initial delay in milliseconds before stepping the value.",
            group: "behavior",
        },

        {
            name: "stepHoldInterval",
            type: "number",
            description:
                "Delay before stepping the value. Can be a number of milliseconds or a function that receives the current step count and returns the delay in milliseconds.",
            group: "behavior",
        },
        {
            name: "suffix",
            type: "string",
            description: "Suffix added after the input value",
            group: "formatting",
        },
        {
            name: "thousandSeparator",
            type: "string",
            description: "A character used to separate thousands",
            group: "formatting",
        },
        {
            name: "thousandsGroupStyle",
            type: "enum",
            options: ["none", "thousand", "lakh", "wan"],
            defaultValue: "none",
            description: "Defines the thousand grouping style.",
            group: "formatting",
        },
        {
            name: "type",
            type: "enum",
            options: ["text", "tel", "password"],
            description: "Controls input type attribute",
            defaultValue: "text",
            group: "input",
        },
        {
            name: "value",
            type: "number",
            description: "Controlled component value",
            important: true,
            priority: 10,
            defaultBindingProp: true,
            supportsWriteback: true,
        },
        // {
        //     name: "valueIsNumericString",
        //     type: "boolean",
        //     description:
        //         "If value is passed as string representation of numbers (unformatted) and number is used in any format props like in prefix or suffix in numeric format and format prop in pattern format then this should be passed as true.",
        //     defaultValue: false,
        //     group: "behavior",
        // },
        inputPointerProp,
        withErrorStyles,
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, decimalScale, iconLeft, iconRight, visible, style, className, margin, ...props } = properties;
        const { setProperty, raiseEvent } = useActions();
        const onChange = async (value: string | number) => {
            setProperty("value", value);
            raiseEvent("change");
        };
        const eventHandlers = { ...useTextInputEvents({}), onChange };
        const inputRef = useWritebackValueOnNativeChange(value);

        ref = useVisibleProp(ref, visible);
        const inputParts = useInlineEditInputParts(props);

        return (
            <NumberInput
                wrapperProps={{ ref }}
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                value={value ?? ""}
                leftSection={makeIcon(iconLeft)}
                rightSection={makeIcon(iconRight)}
                decimalScale={decimalScale ?? undefined}
                ref={inputRef}
                {...eventHandlers}
                {...props}
                {...inputParts}
                {...useMarginStyles(margin)}
            />
        );
    },
});
