import { useMemo } from "react";
import { EventDescription, useActions } from "@anvil-works/anvil-react";

export const FOCUS_EVENTS = [{ name: "focus" }, { name: "blur" }];
export const KEYBOARD_EVENTS = [{ name: "key_down" }, { name: "key_up" }];

export const INPUT_EVENTS = [{ name: "change", defaultEvent: true }, ...FOCUS_EVENTS, ...KEYBOARD_EVENTS];

export const TEXT_INPUT_EVENTS = [...INPUT_EVENTS, { name: "pressed_enter" }];

const anvilToReact = {
    change: "onChange",
    blur: "onBlur",
    focus: "onFocus",
    key_down: "onKeyDown",
    key_up: "onKeyUp",
    pressed_enter: "onKeyDown",
} as Record<string, string>;

interface EventHandlers {
    [key: string]: (e: any) => void;
}

type RaiseEvent = (eventName: string, args?: object | undefined) => Promise<void>;

type EventHandlerGetter = (raiseEvent: RaiseEvent, existingHandlers: EventHandlers) => EventHandlers;

const getKeyboardHandlers = (raiseEvent: RaiseEvent, existingHandlers: EventHandlers) =>
    ({
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
            existingHandlers.onKeydown?.(e);
            raiseEvent("key_down", { event: e });
        },
        onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
            existingHandlers.onKeyup?.(e);
            raiseEvent("key_up", { event: e });
        },
    }) as EventHandlers;

const getFocusHandlers = (raiseEvent: RaiseEvent, existingHandlers: EventHandlers) =>
    ({
        onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
            existingHandlers.onFocus?.(e);
            raiseEvent("focus", { event: e });
        },
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            existingHandlers.onBlur?.(e);
            raiseEvent("blur", { event: e });
        },
    }) as EventHandlers;

export const getInputHandlers = (raiseEvent: RaiseEvent, existingHandlers: EventHandlers) =>
    ({
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            existingHandlers.onChange?.(e);
            raiseEvent("change", { event: e });
        },
        ...getFocusHandlers(raiseEvent, existingHandlers),
        ...getKeyboardHandlers(raiseEvent, existingHandlers),
    }) as EventHandlers;

export const getTextInputHandlers = (raiseEvent: RaiseEvent, existingHandlers: EventHandlers) => {
    const inputHandlers = getInputHandlers(raiseEvent, existingHandlers);
    return {
        ...inputHandlers,
        onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            inputHandlers.onKeyDown?.(e);
            if (e.key !== "Enter") return;
            raiseEvent("pressed_enter", { event: e });
        },
    } as EventHandlers;
};

export const createHandlers = (events: EventDescription[], getEventHandlers: EventHandlerGetter) => {
    return function useHandlers(existingHandlers: EventHandlers) {
        const { raiseEvent } = useActions();

        return useMemo(() => {
            const handlers = getEventHandlers(raiseEvent, existingHandlers);

            return Object.fromEntries(
                events.map(({ name }) => {
                    const reactName = anvilToReact[name];
                    const handler = handlers[reactName];
                    if (!handler) {
                        throw new Error(`No handler for event ${name}`);
                    }
                    return [reactName, handler];
                })
            );
        }, []);
    };
};

export const useFocusEvents = createHandlers(FOCUS_EVENTS, getFocusHandlers);
export const useInputEvents = createHandlers(INPUT_EVENTS, getInputHandlers);
export const useTextInputEvents = createHandlers(TEXT_INPUT_EVENTS, getTextInputHandlers);
