import React from "react";
import { registerComponent } from "@/utils";
import {
    disabledProp,
    inputInteractionProps,
    marginProp,
    override,
    pick,
    placeholderProp,
    radiusProp,
    sizeProp,
    sizes,
    styleProps,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    variant,
    visibleProp,
} from "@/utils/props";
import useEvent from "@/utils/use-event";
import { useActions } from "@anvil-works/anvil-react";
import { PinInput } from "@mantine/core";

export const defaultInputVariant = variant(["default", "filled", "unstyled"], "default");

registerComponent({
    name: "PinInput",
    events: [{ name: "change" }, { name: "complete", defaultEvent: true }],
    properties: [
        defaultInputVariant,
        {
            name: "ariaLabel",
            type: "string",
            description: "aria-label for the inputs",
            group: "input",
        },
        {
            name: "autoFocus",
            type: "boolean",
            description: "If set, the first input is focused when component is mounted",
            defaultValue: false,
            group: "behavior",
        },
        disabledProp,
        {
            name: "error",
            type: "boolean",
            description: "If set, adds error styles and aria-invalid attribute to all inputs",
            group: "input",
        },
        {
            name: "gap",
            type: "enum",
            options: sizes,
            description:
                "Key of theme.spacing or any valid CSS value to set gap between inputs, numbers are converted to rem",
            defaultValue: "md",
            group: "appearance",
        },
        // {
        //     name: "hiddenInputProps",
        //     type: "React.ComponentPropsWithoutRef<'input'>",
        //     description: "Props passed down to the hidden input",
        // },
        // {
        //     name: "id",
        //     type: "string",
        //     description: "Base id used for all inputs. By default, inputs' ids are generated randomly.",
        // },
        // {
        //     name: "inputMode",
        //     type: "enum",
        //     options: ["search", "text", "none", "tel", "url", "email", "numeric", "decimal"],
        //     description: "inputmode attribute, inferred from the type prop if not specified",
        // },
        // {
        //     name: "inputType",
        //     type: "HTMLInputTypeAttribute",
        //     description: "Inputs type attribute, inferred from the type prop if not specified",
        // },
        {
            name: "length",
            type: "number",
            description: "Number of inputs, 4 by default",
            defaultValue: 4,
            important: true,
        },
        {
            name: "manageFocus",
            type: "boolean",
            description: "Determines whether focus should be moved automatically to the next input once filled",
            defaultValue: true,
            group: "behavior",
        },
        {
            name: "mask",
            type: "boolean",
            description: "Changes input type to 'password'",
            group: "behavior",
            defaultValue: false,
        },
        {
            name: "oneTimeCode",
            type: "boolean",
            description: "Determines whether autocomplete='one-time-code' attribute should be set on all inputs",
            defaultValue: true,
            group: "behavior",
        },
        ...override([placeholderProp], {
            placeholder: { description: "Inputs placeholder, '○' by default", defaultValue: "○" },
        }),
        ...pick(inputInteractionProps, ["readOnly"]),
        radiusProp,
        sizeProp,
        {
            name: "type",
            type: "enum",
            options: ["number", "alphanumeric"],
            description: "Determines which values can be entered",
            defaultValue: "alphanumeric",
        },
        {
            name: "value",
            type: "string",
            description: "Controlled component value",
            important: true,
            supportsWriteback: true,
            defaultBindingProp: true,
            priority: 10,
        },
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, visible, style, className, margin, ...props } = properties;
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const onChange = (value: string) => {
            setProperty("value", value);
            raiseEvent("change");
        };
        const onComplete = useEvent(async (value: string) => {
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("complete");
            }
        });
        ref = useVisibleProp(ref, visible);
        return (
            <PinInput
                value={value ?? ""}
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                onChange={onChange}
                onComplete={onComplete}
                rootRef={ref}
                {...props}
                {...useMarginStyles(margin)}
            />
        );
    },
});
