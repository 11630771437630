import { sizes } from "./sizing";

export const heightProp = {
    name: "height",
    // type: "enum",
    // options: sizes,
    type: "string",
    includeNoneOption: true,
    group: "dimensions",
} as const;

export const widthProp = {
    name: "width",
    // type: "enum",
    // options: sizes,
    type: "string",
    includeNoneOption: true,
    group: "dimensions",
} as const;
