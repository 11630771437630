import React, { useEffect, useRef, useState } from "react";
import { registerComponent } from "@/utils";
import { useMergeRefs } from "@/utils/merge-refs";
import { classProp, marginProp, paddingProp, styleProp, useVisibleProp, visibleProp } from "@/utils/props";
import {
    useChainHeightWidth,
    useChainMarginStyles,
    useChainPaddingStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { heightProp, widthProp } from "@/utils/props/dimensions";
import { DropZone, inDesigner } from "@anvil-works/anvil-react/designer";
import { Stack } from "@mantine/core";
import { ChildContainer, flexProperties, pick } from "./flex";

registerComponent({
    name: "Stack",
    container: true,
    properties: [
        ...Object.values(pick(flexProperties, ["align", "gap", "justify"])),
        visibleProp,
        styleProp,
        classProp,
        marginProp,
        paddingProp,
        heightProp,
        widthProp,
    ],
    layoutProperties: [],
    autoDropZones: false,
    component({ childrenWithLayoutProperties, properties }, ref) {
        const { visible, ...props } = useChainProps(properties, [
            useChainStyleAndClassName({ overrides: inDesigner ? { minHeight: 45 } : null }),
            useChainMarginStyles,
            useChainPaddingStyles,
            useChainHeightWidth,
        ]);
        const stackRef = useRef<HTMLDivElement>(null);

        ref = useVisibleProp(ref, visible);
        ref = useMergeRefs(ref, stackRef);

        const numChildren = childrenWithLayoutProperties.length;
        const hasChildren = !!numChildren;
        const [marginBottom, setMarginTop] = useState<string | undefined>(undefined);

        const initDropStyle: React.CSSProperties = hasChildren
            ? { alignSelf: "stretch", marginBottom }
            : { alignSelf: "stretch", margin: 10 };

        useEffect(() => {
            if (!inDesigner) {
                return;
            }
            if (!hasChildren) {
                setMarginTop(undefined);
            }
            const stackEl = stackRef.current;
            if (!stackEl) return;
            const gapValue = getComputedStyle(stackEl)?.getPropertyValue("gap");
            if (!gapValue) {
                setMarginTop(undefined);
            } else {
                setMarginTop(`-${gapValue}`);
            }
        }, [properties.gap, hasChildren]);

        return (
            <Stack ref={ref} {...props}>
                <DropZone style={initDropStyle} minChildIdx={0} maxChildIdx={0} />
                {childrenWithLayoutProperties.map((c, i) => {
                    const isLast = i === numChildren - 1;
                    const style = isLast ? { marginTop: marginBottom } : { marginBottom };
                    return <ChildContainer style={style} key={c.key} child={c} />;
                })}
            </Stack>
        );
    },
});
