import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import {
    disabledProp,
    iconProps,
    inputInteractionProps,
    inputPartProps,
    inputPointerProp,
    marginProp,
    placeholderProp,
    radiusProp,
    sizeProp,
    styleProps,
    useInlineEditInputParts,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    variant,
    visibleProp,
    withErrorStyles,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { FileInput } from "@mantine/core";

const EMPTY_FILES: File[] = [];

registerComponent({
    name: "_FileInput",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        variant(["default", "filled", "unstyled"], "default"),
        {
            name: "accept",
            type: "string",
            description: "File input accept attribute, for example, 'image/png,image/jpeg'",
            group: "input",
        },
        {
            name: "capture",
            type: "enum",
            options: ["user", "environment", "boolean"],
            description:
                "Optionally, a new file should be captured, and which device should be used to capture that new media of a type defined by the accept attribute.",
            group: "input",
        },
        {
            name: "clearable",
            type: "boolean",
            description: "Determines whether clear button should be displayed in the right section, false by default",
            defaultValue: false,
            group: "interaction",
        },
        placeholderProp,
        ...inputPartProps,
        disabledProp,
        ...iconProps,
        {
            name: "multiple",
            type: "boolean",
            description: "Determines whether user can pick more than one file, false by default",
            defaultValue: false,
            group: "input",
        },
        radiusProp,
        sizeProp,
        {
            name: "value",
            type: "object",
            description: "Javascript File or Files value",
            important: false,
            defaultBindingProp: true,
            supportsWriteback: true,
        },
        inputPointerProp,
        ...inputInteractionProps,
        withErrorStyles,
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, multiple, iconLeft, iconRight, visible, style, className, margin, ...props } = properties;
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const onChange = async (file: File | File[] | null) => {
            setProperty("value", file);
            try {
                await triggerWriteBack("value", file);
            } finally {
                raiseEvent("change");
            }
        };
        ref = useVisibleProp(ref, visible);
        const inputParts = useInlineEditInputParts(props);

        return (
            <FileInput
                wrapperProps={{ ref }}
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                multiple={multiple}
                value={value ?? (multiple ? EMPTY_FILES : null)}
                leftSection={makeIcon(iconLeft)}
                rightSection={makeIcon(iconRight)}
                onChange={onChange}
                {...props}
                {...inputParts}
                {...useMarginStyles(margin)}
            />
        );
    },
});
