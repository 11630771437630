import React from "react";
import { registerComponent } from "@/utils";
import { classProp, marginProp, paddingProp, styleProp, useVisibleProp, visibleProp } from "@/utils/props";
import {
    useChainMarginStyles,
    useChainPaddingStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { DropZone, inDesigner, useDropping } from "@anvil-works/anvil-react/designer";
import { Group } from "@mantine/core";
import { ChildContainer, flexProperties, pick } from "./flex";

registerComponent({
    name: "Group",
    container: true,
    properties: [
        ...Object.values(pick(flexProperties, ["align", "gap", "justify", "wrap"])),
        {
            name: "preventGrowOverflow",
            type: "boolean",
            description:
                "Determines whether children should take only dedicated amount of space (max-width style is set based on the number of children), true by default ",
            group: "layout",
        },
        {
            name: "grow",
            type: "boolean",
            description: "Determines whether each child element should have flex-grow: 1 style, false by default",
            group: "layout",
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
        paddingProp,
    ],
    layoutProperties: [],
    autoDropZones: false,
    component({ childrenWithLayoutProperties, properties }, ref) {
        const { visible, ...props } = useChainProps(properties, [
            useChainMarginStyles,
            useChainPaddingStyles,
            useChainStyleAndClassName({ overrides: inDesigner ? { minHeight: 45 } : null }),
        ]);

        const isDropping = useDropping();

        ref = useVisibleProp(ref, visible);
        const numChildren = childrenWithLayoutProperties.length;
        const initDropStyle = numChildren ? { alignSelf: "stretch", flexGrow: 0 } : { flexGrow: 1, margin: 20 };
        const lastStyle = props.grow ? initDropStyle : { flexGrow: 1, height: 0, alignSelf: "center" };

        return (
            <Group ref={ref} {...props}>
                {/* can't render this otherwise grow property is broken */}
                {isDropping && <DropZone style={initDropStyle} minChildIdx={0} maxChildIdx={0} />}
                {childrenWithLayoutProperties.map((c, i) => {
                    return (
                        <ChildContainer
                            key={c.key}
                            child={c}
                            style={i + 1 === numChildren ? lastStyle : initDropStyle}
                        />
                    );
                })}
            </Group>
        );
    },
});
