import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import { TEXT_INPUT_EVENTS, useTextInputEvents } from "@/utils/events";
import {
    disabledProp,
    iconProps,
    inputInteractionProps,
    inputPartProps,
    inputPointerProp,
    marginProp,
    placeholderProp,
    radiusProp,
    sizeProp,
    styleProps,
    useInlineEditInputParts,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
    withErrorStyles,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { useInlineEditRef } from "@anvil-works/anvil-react/designer";
import { TextInput } from "@mantine/core";
import { defaultInputVariant } from "./pin-input";
import { useWritebackValueOnNativeChange } from "./utils";

registerComponent({
    name: "TextInput",
    events: TEXT_INPUT_EVENTS,
    properties: [
        defaultInputVariant,
        ...inputPartProps,
        disabledProp,
        ...iconProps,
        inputPointerProp,
        placeholderProp,
        radiusProp,
        sizeProp,
        ...inputInteractionProps,
        withErrorStyles,
        {
            name: "value",
            type: "string",
            important: true,
            priority: 10,
            supportsWriteback: true,
            defaultBindingProp: true,
        },
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, iconLeft, iconRight, visible, style, className, margin, ...props } = properties;
        const { setProperty } = useActions();

        const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
            const value = e.target.value;
            setProperty("value", value);
        };
        const eventHandlers = useTextInputEvents({ onChange });

        const inputRef = useWritebackValueOnNativeChange(value);

        ref = useVisibleProp(ref, visible);
        const inputParts = useInlineEditInputParts(props, false);

        return (
            <TextInput
                wrapperProps={{ ref }}
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                value={value ?? ""}
                leftSection={makeIcon(iconLeft)}
                rightSection={makeIcon(iconRight)}
                ref={useInlineEditRef("text", inputRef)}
                {...eventHandlers}
                {...props}
                {...inputParts}
                {...useMarginStyles(margin)}
            />
        );
    },
});
