import React, { createContext, useContext, useMemo } from "react";
import { useClientConfig } from "@anvil-works/anvil-react";
import { MantineProvider, createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import { useColorScheme } from "./color-scheme";
import { ExternalStore, useStoreSelector } from "./external-store";
import { ModalProvider } from "./modals";

export const colorStore = new ExternalStore({ primaryColor: "", colors: undefined as any });

const ProviderContext = createContext(false);

export const WrappedMantineProvider = ({ children }: React.PropsWithChildren) => {
    const colorScheme = useColorScheme();
    const clientConfig = useClientConfig("Mantine");
    const primaryColorFromStore = useStoreSelector(colorStore, (s) => s.primaryColor);
    const colorsFromStore = useStoreSelector(colorStore, (s) => s.colors);
    const theme = useMemo(() => {
        const primaryColor = primaryColorFromStore || clientConfig?.primary_color || "blue";
        const colors = colorsFromStore ?? clientConfig?.colors ?? {};
        return createTheme({ primaryColor, colors });
    }, [primaryColorFromStore, colorsFromStore, clientConfig]);

    return (
        <MantineProvider withGlobalClasses theme={theme} {...colorScheme}>
            <ModalProvider />
            {children}
        </MantineProvider>
    );
};

export const ensureTheme = (component: React.ReactElement | null) => {
    const hasTheme = useContext(ProviderContext);
    if (hasTheme) {
        return component;
    } else {
        return (
            <ProviderContext.Provider value={true}>
                <WrappedMantineProvider>{component}</WrappedMantineProvider>
            </ProviderContext.Provider>
        );
    }
};
