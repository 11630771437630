import { useInsertionEffect, useRef } from "react";

/**
 * @description
 * This hook is for event handlers
 * It should never be used for callbacks used inside render methods
 *
 * this was part of an rfc that was parked: https://github.com/reactjs/rfcs/pull/220
 * https://github.com/reactjs/rfcs/pull/220#issuecomment-1259938816
 *
 * This is a polyfill based on: https://stackoverflow.com/questions/76335194/is-this-an-accurate-polyfill-of-reacts-useeffectevent
 * https://github.com/scottrippey/react-use-event-hook/blob/main/src/useEvent.ts
 *
 * Re typing: A specific function type would not trigger implicit any.
 * See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/52873#issuecomment-845806435 for a comparison between `Function` and more specific types.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function useEvent<T extends Function>(fn: T) {
    const ref = useRef<T>(((...args: any[]) => {
        // @ts-ignore
        if (process.env.NODE_ENV !== "production") {
            console.error("Calling a useEvent during the render phase, consider a useCallback instead");
        }
        return fn(...args);
    }) as unknown as T);

    useInsertionEffect(() => {
        ref.current = fn;
    });

    // Create a stable callback that always calls the latest callback:
    // using useRef instead of useCallback avoids creating an empty array on every render
    const stableRef = useRef<T>();
    if (!stableRef.current) {
        stableRef.current = function (this: any) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, prefer-rest-params, @typescript-eslint/no-unsafe-argument
            return ref.current.apply(this, arguments as any);
        } as unknown as T;
    }

    return stableRef.current;
}

export default useEvent;
