import { registerSection } from "@/utils";
import "./app-shell";
import "./center";
import "./container";
import "./flex";
import "./grid";
import "./group";
import "./simple-grid";
import "./space";
import "./stack";

registerSection("Layouts", ["Center", "Container", "Flex", "Grid", "Group", "SimpleGrid", "Space", "Stack"]);
