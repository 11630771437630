import React from "react";

export function Icon({ icon, style }: { icon: string; style?: React.CSSProperties }) {
    const [iconPrefix, iconSuffix] = icon?.split(":") ?? [];
    return icon ? <i style={style} className={`${iconPrefix} fa-${iconSuffix}`} /> : undefined;
}

export function makeIcon(icon: string, props: Omit<React.ComponentProps<typeof Icon>, "icon"> = {}) {
    return icon ? <Icon icon={icon} {...props} /> : undefined;
}
