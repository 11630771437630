import React from "react";
import { registerComponent } from "@/utils";
import { marginProp, paddingProp, sizesWithNone, styleProps, useVisibleProp, visibleProp } from "@/utils/props";
import {
    useChainHeightWidth,
    useChainMarginStyles,
    useChainPaddingStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { inDesigner } from "@anvil-works/anvil-react/designer";
import { Container } from "@mantine/core";

registerComponent({
    name: "Container",
    container: true,
    properties: [
        {
            name: "fluid",
            type: "boolean",
            description: "equivalent to size 100%",
            group: "layout",
        },
        {
            name: "size",
            type: "enum",
            options: sizesWithNone,
            defaultValue: "md",
            description: "Sets max-width of the container",
            group: "layout",
        },
        { name: "height", type: "string", group: "layout" },
        { name: "width", type: "string", group: "layout" },
        visibleProp,
        ...styleProps,
        marginProp,
        paddingProp,
    ],
    layoutProperties: [],
    autoDropZones: true,
    component({ children, properties }, ref) {
        const { visible, ...props } = useChainProps(properties, [
            useChainHeightWidth,
            useChainMarginStyles,
            useChainPaddingStyles,
            useChainStyleAndClassName({ overrides: inDesigner ? { minHeight: 45 } : null }),
        ]);
        ref = useVisibleProp(ref, visible);
        return (
            <Container ref={ref} {...props}>
                {children}
            </Container>
        );
    },
});
