import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import {
    autoContrast,
    colorProp,
    compactSizes,
    disabledProp,
    iconProps,
    inputSizes,
    justifyProp,
    marginProp,
    override,
    radiusProp,
    sizeProp,
    styleProps,
    usePlaceholder,
    useVisibleProp,
    variant,
    visibleProp,
} from "@/utils/props";
import { useChainIconProps, useChainMarginStyles, useChainProps, useChainStyleAndClassName } from "@/utils/props/chain";
import { PropertyDescription, useActions } from "@anvil-works/anvil-react";
import { useInlineEditRef } from "@anvil-works/anvil-react/designer";
import { ActionIcon } from "@mantine/core";

/**
 * 
ActionIcon component props
Name	Type	Description
autoContrast	boolean	Determines whether button text color with filled variant should depend on background-color. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast.
children	React.ReactNode	Icon displayed inside the button
color	MantineColor	Key of theme.colors or any valid CSS color. Default value is theme.primaryColor.
disabled	boolean	Sets disabled and data-disabled attributes on the button element
gradient	MantineGradient	Gradient data used when variant="gradient", default value is theme.defaultGradient
loaderProps	LoaderProps	Props added to the Loader component (only visible when loading prop is set)
loading	boolean	Determines whether Loader component should be displayed instead of the children, false by default
radius	MantineRadius | number	Key of theme.radius or any valid CSS value to set border-radius. Numbers are converted to rem. theme.defaultRadius by default.
size	number | MantineSize | (string & {})	Controls width and height of the button. Numbers are converted to rem. 'md' by default.
ActionIcon.Group component props
Name	Type	Description
borderWidth	string | number	border-width of the child ActionIcon components. Default value in 1
children	React.ReactNode	ActionIcon components only
orientation	"horizontal" | "vertical"	Controls group orientation, 'horizontal' by default

 */
export const actionIconProps = [
    autoContrast,
    variant(["default", "filled", "light", "outline", "subtle", "transparent", "white"], "filled"),
    disabledProp,
    {
        name: "loading",
        type: "boolean",
        group: "interaction",
        description: "Determines whether the Loader component should be displayed over the button",
    },
    {
        name: "icon",
        type: "icon",
        important: true,
    },
    ...override([colorProp, sizeProp, radiusProp], {
        size: {
            options: [...inputSizes],
            description: "Controls width, min-width, min-height and height",
        },
        color: { description: "Key of theme.colors or any valid CSS color, theme.primaryColor by default" },
    }),
    { name: "text", type: "string" },
    ...styleProps,
    marginProp,
    visibleProp,
] satisfies (PropertyDescription & { defaultValue?: any })[];

registerComponent({
    name: "ActionIcon",
    events: [{ name: "click", defaultEvent: true }],
    properties: actionIconProps,
    component: ({ properties: { visible, icon, text, ...props } }, ref) => {
        const { raiseEvent } = useActions();
        const onClick = () => raiseEvent("click");
        props = useChainProps(props, [useChainStyleAndClassName(), useChainMarginStyles]);
        ref = useVisibleProp(ref, visible);

        return (
            <ActionIcon ref={ref} onClick={onClick} {...props}>
                {makeIcon(icon)}
                {text}
            </ActionIcon>
        );
    },
});
