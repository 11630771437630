import { registerSection } from "@/utils";
import "./checkbox";
import "./file-input";
import "./native-select";
import "./number-input";
import "./password-input";
import "./pin-input";
import "./radio";
import "./rating";
import "./slider";
import "./switch";
import "./text-input";
import "./textarea";

registerSection("Inputs", [
    "Checkbox",
    {
        title: "Checkbox Group",
        component: {
            type: "Mantine.CheckboxGroup",
            properties: { label: "Select an option" },
            components: [
                {
                    type: "Mantine.Group",
                    properties: { margin: ["xs", "unset", "unset", "unset"] },
                    components: [
                        { type: "Mantine.Checkbox", properties: { label: "Option 1", value: "option-1" } },
                        { type: "Mantine.Checkbox", properties: { label: "Option 2", value: "option-2" } },
                        { type: "Mantine.Checkbox", properties: { label: "Option 3", value: "option-3" } },
                        { type: "Mantine.Checkbox", properties: { label: "Option 4", value: "option-4" } },
                    ],
                },
            ],
        },
    },
    "FileInput",
    "NativeSelect",
    "NumberInput",
    "PasswordInput",
    "PinInput",
    "Radio",
    {
        title: "Radio Group",
        component: {
            type: "Mantine.RadioGroup",
            properties: { label: "Select an option" },
            components: [
                {
                    type: "Mantine.Group",
                    properties: { margin: ["xs", "unset", "unset", "unset"] },
                    components: [
                        { type: "Mantine.Radio", properties: { label: "Option 1", value: "option-1" } },
                        { type: "Mantine.Radio", properties: { label: "Option 2", value: "option-2" } },
                        { type: "Mantine.Radio", properties: { label: "Option 3", value: "option-3" } },
                        { type: "Mantine.Radio", properties: { label: "Option 4", value: "option-4" } },
                    ],
                },
            ],
        },
    },
    "Rating",
    "TextInput",
    "Textarea",
    "Slider",
    "Switch",
]);
