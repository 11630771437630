import { generateId, registerSection } from "@/utils";
import "./anchor";
import "./breadcrumbs";
import "./nav-link";
import "./tabs";

registerSection("Navigation", [
    "Anchor",
    "NavLink",
    {
        title: "Breadcrumbs",
        component: {
            type: "Mantine.Breadcrumbs",
            components: [{ type: "Mantine.Anchor" }, { type: "Mantine.Anchor" }, { type: "Mantine.Anchor" }],
        },
    },
    {
        title: "Tabs",
        component: {
            type: "Mantine.Tabs",
            properties: {
                tabs: [
                    { id: generateId(), value: "tab-1" },
                    { id: generateId(), value: "tab-2" },
                    { id: generateId(), value: "tab-3" },
                ],
                value: "tab-1",
            },
        },
    },
]);
