import type { PropDef } from ".";

export const justifyProp: PropDef = {
    name: "justify",
    type: "enum",
    options: [
        "center",
        "end",
        "flex-end",
        "flex-start",
        "start",
        "space-around",
        "space-between",
        "space-evenly",
        "stretch",
    ],
    includeNoneOption: true,
    defaultValue: "center",
    group: "layout",
    description: "justify-content CSS property",
};
