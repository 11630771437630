import { registerSection } from "@/utils";
import "./action-icon";
import "./action-icon-group";
import "./button";
import "./button-group";
import "./menu";

registerSection("Buttons", [
    "Button",
    {
        title: "Button Group",
        component: {
            type: "Mantine.ButtonGroup",
            components: [
                { type: "Mantine.Button", properties: { variant: "default" } },
                { type: "Mantine.Button", properties: { variant: "default" } },
                { type: "Mantine.Button", properties: { variant: "default" } },
            ],
        },
    },
    "ActionIcon",
    {
        title: "Action Icon Group",
        component: {
            type: "Mantine.ActionIconGroup",
            components: [
                { type: "Mantine.ActionIcon", properties: { icon: "fa:plus" } },
                { type: "Mantine.ActionIcon", properties: { icon: "fa:pencil" } },
                { type: "Mantine.ActionIcon", properties: { icon: "fa:trash" } },
            ],
        },
    },
    {
        title: "Menu Button",
        component: {
            type: "Mantine.MenuButton",
            components: [
                { type: "Mantine.MenuLabel" },
                { type: "Mantine.MenuItem" },
                { type: "Mantine.MenuItem" },
                { type: "Mantine.MenuDivider" },
                { type: "Mantine.MenuLabel" },
                { type: "Mantine.MenuItem" },
            ],
        },
    },
    "MenuItem",
    "MenuLabel",
    "MenuDivider",
]);
