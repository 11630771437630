import React from "react";
import { inDesigner, useInlineEditRef, useInlineEditRegionRef } from "@anvil-works/anvil-react/designer";
import { PropDef } from ".";

export const inputPartProps = [
    {
        name: "description",
        type: "string",
        group: "input",
        priority: 9,
        description: "Description displayed below the label",
    },
    {
        name: "error",
        type: "string",
        group: "input",
        priority: 8,
        description: "Error message displayed below the label",
    },
    {
        name: "label",
        type: "string",
        group: "input",
        important: true,
        priority: 10,
        description: "Content of the label",
    },
] as const;

export function useInlineEditInputParts({ description, label, error }: any, wholeComponent = true) {
    const descriptionRef = useInlineEditRegionRef<HTMLSpanElement>("description");
    const descriptionEl = description && <span ref={descriptionRef}>{description}</span>;
    const errorRef = useInlineEditRegionRef<HTMLSpanElement>("error");
    const errorEl = error && <span ref={errorRef}>{error}</span>;
    const wholeComponentRef = useInlineEditRef<HTMLSpanElement>("label");
    const labelRef = useInlineEditRegionRef<HTMLSpanElement>("label");
    const labelEl = label && <span ref={wholeComponent ? wholeComponentRef : labelRef}>{label}</span>;
    if (inDesigner) {
        return { description: descriptionEl, label: labelEl, error: errorEl };
    } else {
        return { description, label, error };
    }
}

export const inputInteractionProps = [
    {
        name: "required",
        type: "boolean",
        description:
            "Adds required attribute to the input and a red asterisk on the right side of label, false by default",
        defaultValue: false,
        group: "input",
    },
    {
        name: "readOnly",
        type: "boolean",
        description: "If set, value cannot be changed",
        group: "interaction",
    },

    {
        name: "withAsterisk",
        type: "boolean",
        description:
            "Determines whether the required asterisk should be displayed. Overrides required prop. Does not add required attribute to the input.",
        defaultValue: false,
        group: "input",
    },
] satisfies PropDef[];

export const withErrorStyles = {
    name: "withErrorStyles",
    type: "boolean",
    description:
        "Determines whether the input should have red border and red text color when the error prop is set, true by default",
    defaultValue: true,
    group: "input",
} as const;

export const inputPointerProp = {
    name: "pointer",
    type: "boolean",
    description: "Determines whether the input should have cursor: pointer style, false by default",
    defaultValue: false,
    group: "interaction",
} as const;

export const placeholderProp = {
    name: "placeholder",
    type: "string",
    description: "Input placeholder",
    group: "input",
    important: true,
    priority: 10,
} as const;
