import React, { useMemo } from "react";

type ReactRef<T> = React.RefCallback<T> | React.MutableRefObject<T>;

export function useMergeRefs<T>(...refs: (ReactRef<T> | undefined | null)[]): React.RefCallback<T> | undefined {
    return useMemo(() => {
        const refsToAssign = refs.filter(Boolean);
        if (!refsToAssign.length) return undefined;
        return (node: T) => {
            refsToAssign.forEach((ref) => {
                if (typeof ref === "function") {
                    ref(node);
                } else {
                    ref.current = node;
                }
            });
        };
    }, refs);
}
