import { sizes } from "@/utils/props";
import { ReactComponentDefinition } from "@anvil-works/anvil-react";

export const comboboxProps = [
    {
        name: "data",
        type: "text[]",
        description: "Data used to generate options",
        important: true,
    },
    { name: "placeholder", type: "string", important: true },
    {
        name: "description",
        type: "string",
        description: "Contents of Input.Description component. If not set, description is not rendered.",
        group: "interaction",
    },
    {
        name: "disabled",
        type: "boolean",
        description: "Sets disabled attribute on the input element",
        designerHint: "disabled",
        group: "interaction",
    },
    {
        name: "error",
        type: "string",
        description: "Contents of Input.Error component. If not set, error is not rendered.",
        group: "interaction",
    },
    {
        name: "filter",
        type: "object",
        description: "Function based on which items are filtered and sorted",
    },
    {
        name: "label",
        type: "string",
        description: "Contents of Input.Label component. If not set, label is not rendered.",
        important: true,
    },
    {
        name: "iconLeft",
        type: "icon",
        description: "Content section rendered on the left side of the input",
        group: "icon",
    },
    {
        name: "iconRight",
        type: "icon",
        description: "Content section rendered on the left side of the input",
        group: "icon",
    },
    {
        name: "limit",
        type: "number",
        description: "Maximum number of options displayed at a time, Infinity by default",
        // defaultValue: "Infinity",
    },
    {
        name: "maxDropdownHeight",
        type: "string",
        description: "max-height of the dropdown, only applicable when withScrollArea prop is true, 250 by default",
        defaultValue: "250",
    },
    {
        name: "pointer",
        type: "boolean",
        group: "interaction",
        description: "Determines whether the input should have cursor: pointer style, false by default",
    },
    {
        name: "radius",
        type: "enum",
        options: sizes,
        group: "appearance",
        description:
            "Key of theme.radius or any valid CSS value to set border-radius, numbers are converted to rem, theme.defaultRadius by default",
    },
    {
        name: "required",
        type: "boolean",
        group: "interaction",
        description:
            "Adds required attribute to the input and a red asterisk on the right side of label, false by default",
    },
    {
        name: "selectFirstOptionOnChange",
        type: "boolean",
        description: "Determines whether the first option should be selected when value changes, false by default",
        defaultValue: false,
    },
    {
        name: "size",
        type: "enum",
        options: sizes,
        group: "appearance",
        description: "Controls input height and horizontal padding, 'sm' by default",
    },
    {
        name: "withAsterisk",
        type: "boolean",
        group: "interaction",
        description:
            "Determines whether the required asterisk should be displayed. Overrides required prop. Does not add required attribute to the input. false by default",
    },
    {
        name: "withErrorStyles",
        type: "boolean",
        group: "interaction",
        defaultValue: true,
        description:
            "Determines whether the input should have red border and red text color when the error prop is set, true by default",
    },
] satisfies NonNullable<ReactComponentDefinition["properties"]>;

export const commonSelectProps = [
    {
        name: "checkIconPosition",
        type: "enum",
        options: ["left", "right"],
        description: "Position of the check icon relative to the option label, 'left' by default",
        defaultValue: "left",
        group: "behavior",
    },
    {
        name: "clearable",
        type: "boolean",
        description:
            "Determines whether the clear button should be displayed in the right section when the component has value, false by default",
        defaultValue: false,
        group: "behavior",
    },
    {
        name: "nothingFoundMessage",
        type: "string",
        description:
            "Message displayed when no option matched current search query, only applicable when searchable prop is set",
    },
    {
        name: "searchable",
        type: "boolean",
        description: "Determines whether the select should be searchable, false by default",
        defaultValue: false,
    },
    {
        name: "withCheckIcon",
        type: "boolean",
        description:
            "Determines whether check icon should be displayed near the selected option label, true by default",
        defaultValue: true,
    },
] satisfies NonNullable<ReactComponentDefinition["properties"]>;
