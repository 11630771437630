import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    styleProp,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { Select } from "@mantine/core";
import { comboboxProps, commonSelectProps } from "./utils";

registerComponent({
    name: "Select",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        ...comboboxProps,
        ...commonSelectProps,
        {
            name: "allowDeselect",
            type: "boolean",
            description:
                "Determines whether it should be possible to deselect value by clicking on the selected option, true by default",
            defaultValue: true,
            group: "behavior",
        },
        {
            name: "value",
            type: "object",
            description: "Controlled component value",
            important: true,
            priority: 10,
            defaultBindingProp: true,
            supportsWriteback: true,
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, visible, style, className, margin, iconLeft, iconRight, ...props } = properties;
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const onChange = async (value: string | null) => {
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("change");
            }
        };
        ref = useVisibleProp(ref, visible);
        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);

        return (
            <Select
                wrapperProps={{ ref }}
                styles={{ root: styleObject }}
                classNames={{ root: className }}
                leftSection={makeIcon(iconLeft)}
                rightSection={makeIcon(iconRight)}
                value={value ?? null}
                onChange={onChange}
                {...props}
                {...marginStyles}
            />
        );
    },
});
