import { registerSection } from "@/utils";
import "./avatar";
import "./background-image";
import "./card";
import "./image";
import "./indicator";

registerSection("Data Display", [
    "Avatar",
    {
        title: "Avatar Group",
        component: {
            type: "Mantine.AvatarGroup",
            components: [
                { type: "Mantine.Avatar" },
                { type: "Mantine.Avatar" },
                { type: "Mantine.Avatar" },
                { type: "Mantine.Avatar" },
                { type: "Mantine.Avatar", properties: { text: "+5" } },
            ],
        },
    },
    "BackgroundImage",
    {
        title: "Card",
        component: {
            type: "Mantine.Card",
            properties: { padding: "xl", shadow: "sm" },
            components: [
                { type: "Mantine.CardSection", components: [{ type: "Mantine.Image", properties: { h: 160 } }] },
                {
                    type: "Mantine.Text",
                    properties: {
                        text: "Here is a section",
                        size: "lg",
                        bold: true,
                        margin: ["lg", "unset", "unset", "unset"],
                    },
                },
                {
                    type: "Mantine.Text",
                    properties: { text: "Some more text with information", color: "dimmed" },
                },
            ],
        },
    },
    "CardSection",
    "Image",
    "Indicator",
]);
