import React from "react";
import { registerComponent } from "@/utils";
import { sizes, useVisibleProp, visibleProp } from "@/utils/props";
import { Space } from "@mantine/core";

registerComponent({
    name: "Space",
    properties: [
        { name: "height", type: "enum", options: sizes, includeNoneOption: true, defaultValue: "xs", group: "layout" },
        { name: "width", type: "enum", options: sizes, includeNoneOption: true, group: "layout" },
        visibleProp,
        // styleProp,
        // classProp,
        // marginProp,
    ],
    component({ properties: { visible, height, width, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        return <Space h={height} w={width} ref={ref} {...props} />;
    },
});
