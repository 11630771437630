import React from "react";
import { registerComponent } from "@/utils";
import { useActions } from "@anvil-works/anvil-react";
import { Modal } from "@mantine/core";

registerComponent({
    name: "Modal",
    container: true,
    events: [{ name: "close" }],
    properties: [
        { name: "opened", type: "boolean", important: true },
        { name: "size", type: "string", important: true },
        { name: "title", type: "string", important: true },
        { name: "withCloseButton", type: "boolean", defaultValue: true },
        { name: "centered", type: "boolean", defaultValue: false },
    ],
    autoDropZoneProps: false,
    component({ properties: { opened, ...props }, children }, ref) {
        const { raiseEvent } = useActions();
        const onClose = () => raiseEvent("close");
        return (
            <Modal opened={opened} onClose={onClose} {...props} ref={ref}>
                {children}
            </Modal>
        );
    },
});
