import React, { useMemo } from "react";
import { defaultInputVariant } from "@/inputs/pin-input";
import { registerComponent } from "@/utils";
import {
    PropDef,
    disabledProp,
    iconProps,
    inputInteractionProps,
    inputPartProps,
    inputPointerProp,
    marginProp,
    placeholderProp,
    radiusProp,
    sizeProp,
    styleProps,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import {
    useChainCalendarInteractions,
    useChainDateChange,
    useChainIconProps,
    useChainInputParts,
    useChainMarginStyles,
    useChainProps,
    useChainStyleAndClassName,
    useChainWeekendDays,
} from "@/utils/props/chain";
import { useActions, useComponentState } from "@anvil-works/anvil-react";
import { inDesigner, useInteraction, useSectionRef } from "@anvil-works/anvil-react/designer";
import { CalendarLevel, DateInput, DateValue } from "@mantine/dates";

export const dateFormatterProps = [
    {
        name: "decadeLabelFormat",
        type: "string",
        description:
            "dayjs label format to display decade label or a function that returns decade label based on date value",
        defaultValue: "YYYY",
        group: "formatting",
    },
    {
        name: "monthLabelFormat",
        type: "string",
        description:
            "dayjs label format to display month label or a function that returns month label based on month value",
        defaultValue: "MMMM YYYY",
        group: "formatting",
    },
    {
        name: "monthsListFormat",
        type: "string",
        description: "dayjs format for months list",
        group: "formatting",
        defaultValue: "MMM",
    },
    {
        name: "valueFormat",
        type: "string",
        description: "Dayjs format to display input value, 'MMMM D, YYYY' by default",
        defaultValue: "MMMM D, YYYY",
        group: "formatting",
    },
    {
        name: "weekdayFormat",
        type: "string",
        description: "dayjs format for weekdays names, defaults to 'dd'",
        defaultValue: "dd",
        group: "formatting",
    },
    {
        name: "yearLabelFormat",
        type: "string",
        description:
            "dayjs label format to display year label or a function that returns year label based on year value, defaults to 'YYYY'",
        defaultValue: "YYYY",
        group: "formatting",
    },
    {
        name: "yearsListFormat",
        type: "string",
        description: "dayjs format for years list, 'YYYY' by default",
        defaultValue: "YYYY",
        group: "formatting",
    },
] as const satisfies PropDef[];

export const calendarProps = [
    {
        name: "allowDeselect",
        type: "boolean",
        description:
            "Determines whether value can be deselected when the user clicks on the selected date in the calendar (only when clearable prop is set), defaults to true if clearable prop is set, false otherwise",
        group: "calendar",
    },
    {
        name: "firstDayOfWeek",
        type: "number",
        description: "number 0-6, 0 – Sunday, 6 – Saturday, defaults to 1 – Monday",
        group: "calendar",
    },
    {
        name: "hideOutsideDates",
        type: "boolean",
        description: "Determines whether outside dates should be hidden, defaults to false",
        group: "calendar",
    },
    {
        name: "hideWeekdays",
        type: "boolean",
        description: "Determines whether weekdays row should be hidden, defaults to false",
        group: "calendar",
    },
    {
        name: "locale",
        type: "string",
        description: "dayjs locale, defaults to value defined in DatesProvider",
        group: "calendar",
    },
    {
        name: "numberOfColumns",
        type: "number",
        description: "Number of columns to render next to each other",
        group: "calendar",
    },
    {
        name: "weekendDays",
        type: "text[]",
        defaultValue: ["6", "0"],
        description:
            "Indices of weekend days, 0-6, where 0 is Sunday and 6 is Saturday, defaults to value defined in DatesProvider",
        group: "calendar",
    },
    {
        name: "withCellSpacing",
        type: "boolean",
        description: "Determines whether controls should be separated by spacing, true by default",
        defaultValue: true,
        group: "calendar",
    },
] as const;

export const dateValidationProps = [
    {
        name: "maxDate",
        type: "object",
        description: "Maximum possible date",
        group: "validation",
    },
    {
        name: "maxLevel",
        type: "object",
        description: "Max level that user can go up to (decade, year, month), defaults to decade",
        group: "validation",
    },
    {
        name: "minDate",
        type: "object",
        description: "Minimum possible date",
        group: "validation",
    },
] as const;

export const clearableProp = {
    name: "clearable",
    type: "boolean",
    description: "Determines whether input value can be cleared, adds clear button to right section",
    defaultValue: false,
    group: "behavior",
} as const;

export const dropdownTypeProp = {
    name: "dropdownType",
    type: "enum",
    options: ["popover", "modal"] as string[],
    description: "Type of dropdown, defaults to popover",
    group: "behavior",
} as const;

export function useDateChange() {
    const { setProperty, raiseEvent, triggerWriteBack } = useActions();
    const onChange = async (value: DateValue) => {
        setProperty("value", value);
        try {
            await triggerWriteBack("value", value);
        } finally {
            raiseEvent("change");
        }
    };
    return onChange;
}

export function useCalendarInteractions() {
    const [{ opened, forceHidden, level }, setDesignerState] = useComponentState({
        opened: false,
        forceHidden: false,
        level: "month" as CalendarLevel,
    });

    useInteraction({
        type: "whole_component_multi",
        options: [
            { id: "level:month", name: "Show month" },
            { id: "level:year", name: "Show year" },
            { id: "level:decade", name: "Show decade" },
            { id: "opened:", name: "Hide calendar" },
        ],
        title: "Set designer menu state",
        callbacks: {
            execute(action) {
                const [prop, value] = action.split(":", 2);
                if (prop === "level") {
                    setDesignerState((v) => ({ ...v, forceHidden: false, level: value }));
                } else {
                    setDesignerState((v) => ({ ...v, forceHidden: true }));
                }
            },
        },
    });

    useInteraction({
        type: "designer_events",
        callbacks: {
            onSelectDescendent() {
                setDesignerState((v) => (v.opened ? v : { ...v, opened: true }));
            },
            onSelectOther() {
                setDesignerState((v) => (v.opened ? { ...v, opened: false } : v));
            },
        },
    });
    return inDesigner
        ? {
              popoverProps: { opened: forceHidden ? false : opened, trapFocus: false },
              level,
          }
        : {};
}

export function useWeekendDays(weekendDays: unknown) {
    return useMemo(() => {
        if (Array.isArray(weekendDays)) {
            return weekendDays.map((x) => parseInt(x as string));
        }
        return weekendDays;
    }, [weekendDays]);
}

export const chainCalendarProps = [
    useChainCalendarInteractions,
    useChainDateChange,
    useChainIconProps,
    useChainInputParts,
    useChainMarginStyles,
    useChainStyleAndClassName(),
    useChainWeekendDays,
];

export function useChainCalendarProps(props: Record<string, any>) {
    return useChainProps(props, chainCalendarProps);
}

registerComponent({
    name: "_DateInput",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        defaultInputVariant,
        ...calendarProps,
        ...dateFormatterProps,
        ...dateValidationProps,
        clearableProp,
        ...inputPartProps,
        disabledProp,
        inputPointerProp,
        {
            name: "fixOnBlur",
            type: "boolean",
            description: "Determines whether input value should be reverted to last known valid value on blur",
            defaultValue: true,
            group: "behavior",
        },
        ...iconProps,
        placeholderProp,
        {
            name: "preserveTime",
            type: "boolean",
            description:
                "Determines whether time (hours, minutes, seconds, and milliseconds) should be preserved when new date is picked",
            defaultValue: true,
            group: "behavior",
        },
        radiusProp,
        sizeProp,
        ...inputInteractionProps,
        {
            name: "value",
            type: "object",
            description: "Raw javascript Date value",
            supportsWriteback: true,
            defaultBindingProp: true,
        },
        ...styleProps,
        visibleProp,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, visible, ...props } = useChainCalendarProps(properties);
        ref = useVisibleProp(ref, visible);
        return <DateInput wrapperProps={{ ref }} value={value ?? null} {...props} />;
    },
});
