import { useEffect, useRef } from "react";
import useEvent from "@/utils/use-event";
import { useActions } from "@anvil-works/anvil-react";

export function useWritebackValueOnNativeChange<T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>(
    value: any
) {
    const { triggerWriteBack } = useActions();
    const inputRef = useRef<T>(null);
    const write = useEvent(() => {
        triggerWriteBack("value", value);
    });

    useEffect(() => {
        const input = inputRef.current;
        if (!input) return;
        input.addEventListener("change", write);
        return () => {
            input.removeEventListener("change", write);
        };
    }, []);
    return inputRef;
}
