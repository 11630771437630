import React, { useCallback, useRef } from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import {
    colorProp,
    disabledProp,
    inputPartProps,
    marginProp,
    override,
    radiusProp,
    sizeProp,
    styleProps,
    useInlineEditInputParts,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { useInteraction, useRegionInteractionRef } from "@anvil-works/anvil-react/designer";
import { Switch } from "@mantine/core";

const sizeToFontSize = {
    xs: "x-small",
    sm: "small",
    md: "medium",
    lg: "large",
    xl: "x-large",
} as Record<string, string>;

registerComponent({
    name: "Switch",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        colorProp,
        ...inputPartProps,
        ...override([radiusProp, sizeProp], { radius: { defaultValue: "xl" } }),
        {
            name: "labelPosition",
            type: "enum",
            options: ["left", "right"],
            defaultValue: "right",
            group: "input",
            description: "Position of the label relative to the input, 'right' by default",
        },
        {
            name: "offIcon",
            type: "icon",
            description: "Inner label when the Switch is in unchecked state",
            group: "switch",
        },
        {
            name: "onIcon",
            type: "icon",
            group: "switch",
            description: "Inner label when the Switch is in checked state",
        },
        {
            name: "offLabel",
            type: "string",
            group: "switch",
            description: "Inner label when the Switch is in unchecked state",
        },
        {
            name: "onLabel",
            type: "string",
            group: "switch",
            description: "Inner label when the Switch is in checked state",
        },
        // { name: "thumbIcon", type: "React.ReactNode", description: "Icon inside the thumb of the switch" },
        // { name: "wrapperProps", type: "Record<string, any>", description: "Props passed down to the root element" },
        {
            name: "checked",
            type: "boolean",
            defaultValue: false,
            description: "",
            important: true,
            priority: 10,
            supportsWriteback: true,
            defaultBindingProp: true,
            designerHint: "toggle",
        },
        disabledProp,
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties }, ref) {
        const { checked, visible, style, className, margin, onIcon, offIcon, onLabel, offLabel, ...props } = properties;
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
            const checked = e.target.checked;
            setProperty("checked", checked);
            try {
                await triggerWriteBack("checked", checked);
            } finally {
                raiseEvent("change");
            }
        };
        ref = useVisibleProp(ref, visible);

        // we don't have access to this usually so a bit of a hack;
        const thumbRef = useRef<any>(null);
        const inputRef = useCallback((inputNode: HTMLInputElement | null) => {
            thumbRef.current = inputNode?.nextElementSibling ?? null;
        }, []);
        useInteraction({
            type: "region",
            sensitivity: 0,
            bounds: thumbRef,
            callbacks: {
                execute() {
                    setProperty("checked", !checked);
                },
            },
        });
        const inputParts = useInlineEditInputParts(props);

        return (
            <Switch
                classNames={{ root: className }}
                checked={!!checked}
                styles={{ root: useStyleObject(style) }}
                ref={inputRef}
                onChange={onChange}
                rootRef={ref}
                onLabel={
                    <React.Fragment>
                        {makeIcon(onIcon, { style: { fontSize: sizeToFontSize[props.size] ?? "small" } })}
                        {onLabel}
                    </React.Fragment>
                }
                offLabel={
                    <React.Fragment>
                        {makeIcon(offIcon, { style: { fontSize: sizeToFontSize[props.size] ?? "small" } })}
                        {offLabel}
                    </React.Fragment>
                }
                {...props}
                {...inputParts}
                {...useMarginStyles(margin)}
            />
        );
    },
});
