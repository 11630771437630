import React, { useRef } from "react";
import { registerComponent } from "@/utils";
import { omit, pick, sizes, sizesWithZero, snakeCasePropDescriptors } from "@/utils/props";
import { ReactComponentDefinition, useActions, useComponentState } from "@anvil-works/anvil-react";
import {
    DropZone,
    inDesigner,
    useDesignerApi,
    useDesignerInteractionRef,
    useDropping,
    useInteraction,
    useRegionInteractionRef,
    useSectionRef,
} from "@anvil-works/anvil-react/designer";
import { AppShell, Burger, Group, ScrollArea, Skeleton, Text } from "@mantine/core";

type ChildWithLayoutProperties = Parameters<
    Parameters<typeof registerComponent>[0]["component"]
>[0]["childrenWithLayoutProperties"];

interface RepeatSlotProps {
    slot: string;
    children?: ChildWithLayoutProperties;
    style?: React.CSSProperties;
    fallback?: React.ReactNode;
}
/**
 * probably
 *
 * navbar_desktop_start_open (maybe add this later)
 * show_burger_on_desktop (maybe add this later)
 *
 * show_navbar - probably infer this from nav links
 *
 * header_collapsed
 * footer_collapsed
 *
 * navbar_collapsed_mobile
 * navbar_collapsed_desktop
 *
 * designer state for navbar rather than toggling real state
 * probably want to hide navbar if no navlinks
 *
 *
 * notify interactions change?
 *
 */

const filterChildren = (children: ChildWithLayoutProperties, slot: string) =>
    children.filter(({ layoutProperties }) => layoutProperties.slot === slot);

const RepeatSlot = ({ slot, children = [], style, fallback }: RepeatSlotProps) => {
    const ourChildren = children.filter(({ layoutProperties }) => layoutProperties.slot === slot);
    const renderChildren = ourChildren.map(({ child, childIdx, key }) => {
        return (
            <React.Fragment key={key}>
                <DropZone style={style} minChildIdx={childIdx} maxChildIdx={childIdx} layoutProperties={{ slot }} />
                {child}
            </React.Fragment>
        );
    });
    const isDropping = useDropping();
    const hasChildren = !!ourChildren.length;
    const showFallback = !hasChildren && inDesigner;
    const lastIndex = ourChildren.at(-1)?.childIdx;
    const lastDropZoneIndex = lastIndex === undefined ? lastIndex : lastIndex + 1;
    return (
        <React.Fragment>
            {showFallback && !isDropping && fallback}
            {renderChildren}
            <DropZone
                key={`app-shell-${slot}-dz-1`}
                style={{ ...style }}
                layoutProperties={{ slot }}
                minChildIdx={lastDropZoneIndex}
                maxChildIdx={lastDropZoneIndex}>
                {showFallback && isDropping && fallback}
            </DropZone>
        </React.Fragment>
    );
};

const NavLinkFallback = (
    <React.Fragment>
        <Text c="dimmed" styles={{ root: { wordWrap: "break-word" } }}>
            To add a side bar, Drop Nav Links here
        </Text>
        {Array(5)
            .fill(0)
            .map((_, index) => (
                <Skeleton key={index} h={28} mt="sm" animate={false} />
            ))}
    </React.Fragment>
);

function useBurgerRef(onToggle: () => void) {
    const regionRef = useRegionInteractionRef<HTMLButtonElement>(onToggle, null, { sensitivity: 2 });
    return useDesignerInteractionRef("dblclick", onToggle, regionRef);
}

const appShellProperties = [
    {
        name: "disabled",
        type: "boolean",
        description: "If set, Navbar, Aside, Header and Footer components be hidden",
        designerHint: "disabled",
    },
    // { name: "breakpoint", type: "enum", options: sizes, defaultValue: "sm" },
    // { name: "padding", type: "enum", options: sizesWithZero, defaultValue: "md" },
    { name: "layout", type: "enum", options: ["default", "alt"], defaultValue: "default", important: true },
    // { name: "header", type: "object", defaultValue: { height: 60, collapsed: false } },
    // {
    //     name: "navbar",
    //     type: "object",
    //     defaultValue: { width: 300, breakpoint: "sm", collapsed: { mobile: true, desktop: false } },
    // },
    // { name: "footer", type: "object", defaultValue: { height: 60, collapsed: false } },
    { name: "header_height", type: "number", defaultValue: 60, group: "header" },
    { name: "header_collapsed", type: "boolean", defaultValue: false, group: "header" },
    { name: "footer_height", type: "number", defaultValue: 60, group: "footer" },
    { name: "footer_collapsed", type: "boolean", defaultValue: false, group: "footer" },
    { name: "navbar_collapsed_mobile", type: "boolean", defaultValue: true, group: "navbar" },
    { name: "navbar_collapsed_desktop", type: "boolean", defaultValue: false, group: "navbar" },
    { name: "navbar_width", type: "number", defaultValue: 300, group: "navbar" },
    {
        name: "navbar_breakpoint",
        type: "enum",
        options: sizesWithZero,
        defaultValue: "sm",
        description: "When the navbar switches from mobile to desktop mode",
        group: "navbar",
    },
    { name: "burger_show_desktop", type: "boolean", defaultValue: true, group: "navbar" },
] satisfies ReactComponentDefinition["properties"];

function useAppShellSection(id: string, props: Record<string, any>) {
    const { setProperty } = useActions();
    return useSectionRef<HTMLDivElement>({
        id,
        sectionProperties: pick(appShellProperties, (name) => name.startsWith(id)),
        sectionPropertyValues: props,
        title: id[0].toUpperCase() + id.slice(1),
        setSectionPropertyValues(updates) {
            for (const [prop, val] of Object.entries(updates)) {
                setProperty(prop, val);
            }
        },
    });
}

registerComponent({
    name: "_AppShell",
    container: true,
    properties: appShellProperties,
    layoutProperties: [{ name: "slot", type: "enum", options: ["navbar", "main", "header"] }],
    autoDropZones: false,
    component({ childrenWithLayoutProperties, properties }, ref) {
        let {
            layout,
            navbar_breakpoint,
            navbar_collapsed_desktop,
            navbar_collapsed_mobile,
            navbar_width,
            header_height,
            header_collapsed,
            footer_height,
            footer_collapsed,
            disabled,
        } = properties;
        let mobileOpened = !navbar_collapsed_mobile;
        let desktopOpened = !navbar_collapsed_desktop;

        const { raiseEvent, setProperty } = useActions();

        useInteraction({
            type: "whole_component_multi",
            options: [
                { id: "footer_collapsed", name: "Toggle footer", icon: "add" },
                { id: "header_collapsed", name: "Toggle header", icon: "add" },
                { id: "navbar_collapsed_mobile", name: "Toggle mobile navbar", icon: "add" },
                { id: "navbar_collapsed_desktop", name: "Toggle desktop navbar", icon: "add" },
                // { id: "aside", name: "Toggle aside", icon: "add" },
                { id: "disabled", name: "Toggle all", icon: "add" },
            ],
            title: "Toggle",
            callbacks: {
                execute(id) {
                    setProperty(id, !properties[id]);
                },
            },
        });

        const onToggleMobile = () => {
            setProperty("navbar_collapsed_mobile", !navbar_collapsed_mobile);
        };
        const onToggleDesktop = () => {
            setProperty("navbar_collapsed_desktop", !navbar_collapsed_desktop);
        };

        const burgerRefMobile = useBurgerRef(onToggleMobile);
        const burgerRefDesktop = useBurgerRef(onToggleDesktop);

        const headerRef = useAppShellSection("header", { header_height, header_collapsed });
        const navRef = useAppShellSection("navbar", {
            navbar_breakpoint,
            navbar_collapsed_desktop,
            navbar_collapsed_mobile,
        });
        const footerRef = useAppShellSection("footer", { footer_height, footer_collapsed });

        const pointerStyles = inDesigner ? { cursor: "pointer" } : undefined;

        const navChildren = filterChildren(childrenWithLayoutProperties, "navbar");
        const hasNavChildren = !!navChildren.length;

        return (
            <AppShell
                ref={ref}
                layout={layout}
                disabled={disabled}
                header={{ height: header_height ?? 60, collapsed: header_collapsed }}
                navbar={{
                    width: navChildren.length ? navbar_width ?? 300 : 100,
                    breakpoint: navbar_breakpoint ?? "sm",
                    // breakpoint: inDesigner ? 0 : navbar.breakpoint ?? "sm",
                    collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
                }}
                footer={{ height: footer_height ?? 60, collapsed: footer_collapsed }}
                // aside={{ width: 300, breakpoint: "md", collapsed: { desktop: false, mobile: true } }}
                padding="md">
                <AppShell.Header ref={headerRef} styles={{ header: pointerStyles }}>
                    <Group h="100%" px="md">
                        {hasNavChildren && (
                            <Burger
                                key="mobile"
                                ref={burgerRefMobile}
                                opened={mobileOpened}
                                onClick={onToggleMobile}
                                hiddenFrom={navbar_breakpoint ?? "sm"}
                                size={navbar_breakpoint ?? "sm"}
                            />
                        )}
                        {hasNavChildren && (
                            <Burger
                                key="desktop"
                                ref={burgerRefDesktop}
                                opened={desktopOpened}
                                onClick={onToggleDesktop}
                                visibleFrom={navbar_breakpoint ?? "sm"}
                                size={navbar_breakpoint ?? "sm"}
                            />
                        )}
                        <RepeatSlot
                            style={{
                                height: "100%",
                                display: "inline-flex",
                                alignItems: "center",
                            }}
                            slot="header"
                            fallback={<Text c="dimmed">Drop a Title or a Group Here</Text>}>
                            {childrenWithLayoutProperties}
                        </RepeatSlot>
                    </Group>
                </AppShell.Header>
                <AppShell.Navbar p="md" ref={navRef} styles={{ navbar: pointerStyles }}>
                    <AppShell.Section grow my="md" component={ScrollArea}>
                        <RepeatSlot fallback={NavLinkFallback} slot="navbar">
                            {childrenWithLayoutProperties}
                        </RepeatSlot>
                    </AppShell.Section>
                </AppShell.Navbar>
                <AppShell.Main styles={{ main: pointerStyles }}>
                    <RepeatSlot fallback={<Text c="dimmed">Drop a Container in here</Text>} slot="main">
                        {childrenWithLayoutProperties}
                    </RepeatSlot>
                </AppShell.Main>
                <AppShell.Footer p="md" ref={footerRef} styles={{ footer: pointerStyles }}>
                    <RepeatSlot fallback={<Text c="dimmed">Drop a Group in here</Text>} slot="footer">
                        {childrenWithLayoutProperties}
                    </RepeatSlot>
                </AppShell.Footer>
            </AppShell>
        );
    },
});
