import React, { useEffect, useRef } from "react";
import { propertyUtils, useVisibility } from "@anvil-works/anvil-react";
import { useMergeRefs } from "../merge-refs";

export const visibleProp = {
    name: "visible",
    type: "boolean",
    group: "appearance",
    // important: true,
    designerHint: "visible",
    defaultValue: true,
} as const;

export function useSetVisibility<T extends HTMLElement>(ref: React.Ref<T> | undefined, visible: any) {
    const localRef = useRef<T>(null);
    const mergedRefs = useMergeRefs(ref, localRef);
    useEffect(() => {
        const localRefEl = localRef.current;
        if (!localRefEl) return;
        propertyUtils.setElementVisibility(localRefEl, visible);
    }, [visible]);
    return mergedRefs;
}

export function useVisibleProp<T extends HTMLElement>(ref: React.Ref<T> | undefined, visible: any) {
    visible = !!visible;
    useVisibility(visible);
    return useSetVisibility(ref, visible);
}
