import React from "react";
import { registerComponent } from "@/utils";
import { classProp, marginProp, sizesWithZero, styleProp, useVisibleProp, visibleProp } from "@/utils/props";
import { useChainMarginStyles, useChainProps, useChainStyleAndClassName } from "@/utils/props/chain";
import { inDesigner, useDropping } from "@anvil-works/anvil-react/designer";
import { Breadcrumbs } from "@mantine/core";

/*
Breadcrumbs component props
Name	Type	Description
children *	React.ReactNode	React nodes that should be separated with separator
separator	React.ReactNode	Separator between children, '/' by default
separatorMargin	MantineSpacing	Controls spacing between separator and breadcrumb, 'xs' by default
*/

registerComponent({
    name: "Breadcrumbs",
    container: true,
    properties: [
        {
            name: "separator",
            type: "string",
            description: "Separator between children, '/' by default",
            group: "separator",
        },
        {
            name: "separatorMargin",
            type: "enum",
            options: sizesWithZero,
            description: "Controls spacing between separator and breadcrumb, 'xs' by default",
            defaultValue: "xs",
            group: "separator",
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    layoutProperties: [],
    component({ children, properties, childrenWithoutDropZones }, ref) {
        const { visible, ...props } = useChainProps(properties, [
            useChainMarginStyles,
            useChainStyleAndClassName({ overrides: inDesigner ? { minHeight: 20 } : null }),
        ]);
        const isDropping = useDropping();

        ref = useVisibleProp(ref, visible);
        return (
            <Breadcrumbs ref={ref} {...props}>
                {isDropping ? children : childrenWithoutDropZones}
            </Breadcrumbs>
        );
    },
});
