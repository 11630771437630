import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import {
    sizesWithNone,
    sizesWithZero,
    snakeCasePropDescriptors,
    toSnake,
    usePlaceholder,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { ReactComponentDefinition, useActions, useComponentState } from "@anvil-works/anvil-react";
import {
    inDesigner,
    useDesignerInteractionRef,
    useInlineEditRef,
    useInteraction,
    useSectionRef,
} from "@anvil-works/anvil-react/designer";
import { Menu } from "@mantine/core";
import { buttonProps, useMantineButton } from "./button";

const menuButtonProps = buttonProps;

const menuProps = [
    // {
    //     name: "designerForceShow",
    //     type: "boolean",
    //     defaultValue: false,
    //     hidden: true,
    //     designerHint: "toggle",
    // },
    {
        name: "arrowOffset",
        type: "number",
        description: "Arrow offset in px, 5 by default",
        defaultValue: 5,
        group: "arrow",
    },
    {
        name: "arrowPosition",
        type: "enum",
        options: ["center", "side"],
        description: "Arrow position",
        defaultValue: "side",
        group: "arrow",
    },
    {
        name: "arrowRadius",
        type: "number",
        description: "Arrow border-radius in px, 0 by default",
        defaultValue: 0,
        group: "arrow",
    },
    {
        name: "arrowSize",
        type: "number",
        description: "Arrow size in px, 7 by default",
        defaultValue: 7,
        group: "arrow",
    },
    // {
    //     name: "clickOutsideEvents",
    //     type: "string[]",
    //     description: "Events that trigger outside clicks",
    //     group: "behavior",
    // },
    {
        name: "closeDelay",
        type: "number",
        description: "Close delay in ms, applicable only to trigger='hover' variant",
        group: "menu",
        defaultValue: 100,
    },
    {
        name: "closeOnClickOutside",
        type: "boolean",
        description: "Determines whether dropdown should be closed on outside clicks",
        group: "menu",
        defaultValue: true,
    },
    {
        name: "closeOnEscape",
        type: "boolean",
        description: "Determines whether dropdown should be closed when Escape key is pressed",
        group: "menu",
        defaultValue: true,
    },
    {
        name: "closeOnItemClick",
        type: "boolean",
        description: "Determines whether Menu should be closed when item is clicked",
        group: "menu",
        defaultValue: true,
    },
    {
        name: "defaultOpened",
        type: "boolean",
        description: "Uncontrolled menu initial opened state",
        group: "menu",
    },
    {
        name: "menuDisabled",
        type: "boolean",
        description: "If set, popover dropdown will not be rendered",
        group: "menu",
        // designerHint: "disabled",
    },
    // {
    //     name: "floatingStrategy",
    //     type: "FloatingStrategy",
    //     description: "Changes floating ui position strategy, 'absolute' by default",
    //     defaultValue: "absolute",
    //     group: "behavior",
    // },
    // {
    //     name: "id",
    //     type: "string",
    //     description: "id base to create accessibility connections",
    //     group: "accessibility",
    // },
    // {
    //     name: "keepMounted",
    //     type: "boolean",
    //     description:
    //         "If set dropdown will not be unmounted from the DOM when it is hidden, display: none styles will be added instead",
    //     group: "behavior",
    // },
    {
        name: "loop",
        type: "boolean",
        description: "Determines whether arrow key presses should loop though items (first to last and last to first)",
        group: "menu",
        defaultValue: true,
    },
    {
        name: "menuItemTabIndex",
        type: "enum",
        options: ["0", "-1"],
        description: "Set the tabindex on all menu items. Defaults to -1",
        defaultValue: "-1",
        group: "menu",
    },
    // {
    //     name: "middlewares",
    //     type: "PopoverMiddlewares",
    //     description:
    //         "Floating ui middlewares to configure position handling, { flip: true, shift: true, inline: false } by default",
    //     defaultValue: "{ flip: true, shift: true, inline: false }",
    //     group: "behavior",
    // },
    {
        name: "offset",
        type: "number",
        description: "Offset of the dropdown element, 8 by default",
        defaultValue: 8,
        group: "menu",
    },
    {
        name: "openDelay",
        type: "number",
        description: "Open delay in ms, applicable only to trigger='hover' variant",
        group: "menu",
        defaultValue: 0,
    },
    // {
    //     name: "opened",
    //     type: "boolean",
    //     description: "Controlled menu opened state",
    //     group: "controlled",
    // },
    // {
    //     name: "portalProps",
    //     type: "Omit<PortalProps, 'children'>",
    //     description: "Props to pass down to the Portal when withinPortal is true",
    //     group: "behavior",
    // },
    {
        name: "position",
        type: "enum",
        options: [
            "top",
            "right",
            "bottom",
            "left",
            "top-start",
            "right-start",
            "bottom-start",
            "left-start",
            "top-end",
            "right-end",
            "bottom-end",
            "left-end",
        ],
        description: "Dropdown position relative to the target element, 'bottom' by default",
        defaultValue: "bottom",
        group: "menu",
    },
    // {
    //     name: "positionDependencies",
    //     type: "any[]",
    //     description: "useEffect dependencies to force update dropdown position, [] by default",
    //     defaultValue: "[]",
    //     group: "behavior",
    // },
    {
        name: "menuRadius",
        type: "enum",
        options: sizesWithZero,
        description: "Key of theme.radius or any valid CSS value to set border-radius, theme.defaultRadius by default",
        defaultValue: "sm",
        group: "menu",
    },
    {
        name: "returnFocus",
        type: "boolean",
        description:
            "Determines whether focus should be automatically returned to control when dropdown closes, false by default",
        defaultValue: false,
        group: "menu",
    },
    {
        name: "shadow",
        type: "enum",
        options: sizesWithNone,
        description: "Key of theme.shadows or any other valid CSS box-shadow value",
        group: "menu",
    },
    // {
    //     name: "transitionProps",
    //     type: "TransitionProps",
    //     description:
    //         "Props passed down to the Transition component that used to animate dropdown presence, use to configure duration and animation type, { duration: 150, transition: 'fade' } by default",
    //     defaultValue: "{ duration: 150, transition: 'fade' }",
    //     group: "behavior",
    // },
    {
        name: "trapFocus",
        type: "boolean",
        description: "Determines whether dropdown should trap focus of keyboard events",
        group: "menu",
        defaultValue: true,
    },
    {
        name: "trigger",
        type: "enum",
        options: ["hover", "click", "click-hover"],
        description: "Event which should open menu",
        group: "menu",
        defaultValue: "click",
    },
    {
        name: "width",
        type: "string",
        description:
            "Dropdown width, or 'target' to make dropdown width the same as target element, 'max-content' by default",
        defaultValue: "max-content",
        group: "menu",
    },
    {
        name: "withArrow",
        type: "boolean",
        description: "Determines whether component should have an arrow, false by default",
        defaultValue: false,
        group: "arrow",
    },
    {
        name: "withinPortal",
        type: "boolean",
        description: "Determines whether dropdown should be rendered within the Portal, true by default",
        defaultValue: true,
        group: "menu",
    },
    {
        name: "zIndex",
        type: "number",
        description: "Dropdown z-index, 300 by default",
        defaultValue: 300,
        group: "menu",
    },
] satisfies ReactComponentDefinition["properties"];

const {
    snakeProperties: menuSnakeProperties,
    camelCaseProps: camelCaseMenuProps,
    snakeCaseProps: snakeCaseMenuProps,
} = snakeCasePropDescriptors(menuProps);

const menuKeys = menuProps.map((k) => k.name);
const buttonKeys = buttonProps.map((k) => k.name);
const getProps = (props: Record<string, any>, propNames: string[]) => {
    const rv: Record<string, any> = {};
    for (const prop of propNames) {
        rv[prop] = props[prop];
    }
    return rv;
};

registerComponent({
    name: "MenuButton",
    events: [{ name: "click", defaultEvent: true }],
    container: true,
    properties: [...menuButtonProps, ...menuProps],
    layoutProperties: [],
    autoDropZones: true,
    component({ children, properties }, ref) {
        const { menuRadius, menuDisabled, ...menuProps } = getProps(properties, menuKeys);
        const buttonProps = getProps(properties, buttonKeys);

        const { setProperty } = useActions();
        const [{ isOpen, mode }, setDesignerState] = useComponentState({ mode: "SELECT", isOpen: false });

        useInteraction({
            type: "whole_component_multi",
            options: [
                { id: "SELECT", name: "Show menu on button select" },
                { id: "SHOW", name: "Keep menu visible" },
                { id: "HIDE", name: "Keep menu hidden" },
            ],
            title: "Set designer menu state",
            callbacks: {
                execute(mode) {
                    const isOpen = mode === "SHOW" || mode === "SELECT";
                    setDesignerState((v) => (v.mode === mode && v.isOpen === isOpen ? v : { mode, isOpen }));
                },
            },
        });

        useInteraction({
            type: "designer_events",
            callbacks: {
                onSelectDescendent() {
                    mode === "SELECT" && setDesignerState((v) => (v.isOpen ? v : { ...v, isOpen: true }));
                },
                onSelectOther() {
                    mode === "SELECT" && setDesignerState((v) => (v.isOpen ? { ...v, isOpen: false } : v));
                },
            },
        });

        const targeRef = useDesignerInteractionRef(
            "dblclick",
            () => {
                setDesignerState((v) => ({ mode: "SELECT", isOpen: !v.isOpen }));
            },
            ref
        );

        const menuRef = useSectionRef<HTMLDivElement>({
            id: "menu",
            title: "menu",
            sectionProperties: menuSnakeProperties,
            sectionPropertyValues: snakeCaseMenuProps(menuProps) ?? {},
            setSectionPropertyValues(updates) {
                for (const [p, v] of Object.entries(updates)) {
                    setProperty(toSnake(p), v);
                }
            },
        });

        return (
            <Menu
                disabled={inDesigner ? undefined : menuDisabled}
                keepMounted
                radius={menuRadius}
                {...menuProps}
                opened={inDesigner ? isOpen : undefined}>
                <Menu.Target ref={targeRef}>{useMantineButton(buttonProps)}</Menu.Target>
                <Menu.Dropdown
                    classNames={{
                        dropdown: menuDisabled || buttonProps.disabled ? "anvil-mantine-hidden-children" : undefined,
                    }}
                    ref={menuRef}
                    styles={{ dropdown: inDesigner ? { minHeight: 45 } : undefined }}>
                    {children}
                </Menu.Dropdown>
            </Menu>
        );
    },
});

registerComponent({
    name: "MenuLabel",
    properties: [{ name: "label", type: "string", important: true }, visibleProp],
    layoutProperties: [],
    component({ properties: { visible, label, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        const [placeholder, inlineEditOptions] = usePlaceholder(label);
        return (
            <Menu.Label ref={useInlineEditRef("label", ref, inlineEditOptions)} {...props}>
                {label || placeholder}
            </Menu.Label>
        );
    },
});

registerComponent({
    name: "MenuDivider",
    component(_, ref) {
        return <Menu.Divider ref={ref} />;
    },
});

registerComponent({
    name: "MenuItem",
    events: [{ name: "click", defaultEvent: true }],
    properties: [
        {
            name: "color",
            type: "color",
            group: "appearance",
            description: "Key of theme.colors or any valid CSS color, theme.primaryColor by default",
        },
        {
            name: "disabled",
            type: "boolean",
            description: "Indicates disabled state",
            group: "interaction",
            designerHint: "disabled",
        },
        {
            name: "iconLeft",
            type: "icon",
            description: "",
        },
        {
            name: "iconRight",
            type: "icon",
            description: "",
        },
        { name: "label", type: "string", important: true },
        visibleProp,
    ],
    component({ properties: { visible, label, iconLeft, iconRight, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        const { raiseEvent } = useActions();
        const [placeholder, inlineEditOptions] = usePlaceholder(label);
        const labelEl = <span ref={useInlineEditRef("label", null, inlineEditOptions)}>{label || placeholder}</span>;
        return (
            <Menu.Item
                ref={ref}
                onClick={() => raiseEvent("click")}
                leftSection={makeIcon(iconLeft)}
                rightSection={makeIcon(iconRight)}
                {...props}>
                {labelEl}
            </Menu.Item>
        );
    },
});
