import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import {
    classProp,
    disabledProp,
    iconProps,
    inputInteractionProps,
    inputPartProps,
    marginProp,
    radiusProp,
    sizeProp,
    styleProp,
    useInlineEditInputParts,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    variant,
    visibleProp,
    withErrorStyles,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { NativeSelect } from "@mantine/core";

registerComponent({
    name: "NativeSelect",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        variant(["default", "filled", "unstyled"], "default"),
        {
            name: "data",
            type: "text[]",
            description: "Data used to render options, can be replaced with children",
            important: true,
        },
        ...inputPartProps,
        ...iconProps,
        disabledProp,
        radiusProp,
        sizeProp,
        ...inputInteractionProps,
        withErrorStyles,
        {
            name: "value",
            type: "string",
            important: true,
            priority: 10,
            supportsWriteback: true,
            defaultBindingProp: true,
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, iconLeft, iconRight, visible, style, className, margin, ...props } = properties;
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const onChange: React.ChangeEventHandler<HTMLSelectElement> = async (e) => {
            const value = e.target.value;
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("change");
            }
        };
        ref = useVisibleProp(ref, visible);

        return (
            <NativeSelect
                wrapperProps={{ ref }}
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                value={value ?? ""}
                leftSection={makeIcon(iconLeft)}
                rightSection={makeIcon(iconRight)}
                onChange={onChange}
                {...props}
                {...useInlineEditInputParts(props)}
                {...useMarginStyles(margin)}
            />
        );
    },
});
