import React from "react";
import {
    ReactComponentDefinition,
    ToolboxSection,
    includeContext,
    registerReactComponent,
} from "@anvil-works/anvil-react";
import { registerToolboxSection } from "@anvil-works/anvil-react/designer";
import { snakeCasePropDescriptors } from "./props";
import { WrappedMantineProvider, ensureTheme } from "./provider";

type ToolboxOptions = { title: string; items: string[] };

const camelToSnakeRegex = /([A-Z])/g;
const snakeToCamelRegex = /(_\w)/g;

function toSnake(str: string): string {
    return str.replace(camelToSnakeRegex, (letter) => `_${letter.toLowerCase()}`);
}

function toCamel(str: string): string {
    return str.replace(snakeToCamelRegex, (matches) => matches[1].toUpperCase());
}

export function registerComponent({ name, component, properties, ...options }: ReactComponentDefinition) {
    const { snakeProperties, camelCaseProps } = snakeCasePropDescriptors(properties);

    return registerReactComponent({
        name: "Mantine." + name,
        properties: snakeProperties,
        component: ({ properties, ...props }, ref) => {
            const camelProps = camelCaseProps(properties);
            return component({ ...props, properties: camelProps }, ref);
            // return ensureTheme(component({ ...props, properties: camelProps }, ref));
        },
        ...options,
    });
}

export function registerSection(title: string, items: (string | ToolboxSection["items"][number])[]) {
    return registerToolboxSection({
        packageName: "Mantine",
        title,
        items: items.map((x) => {
            if (typeof x === "string") {
                const title = x
                    .split(".")
                    .at(-1)!
                    .replace(/([a-z])([A-Z])/g, "$1 $2");
                const fileName = "asset:mantine-icons/" + title.replace(/\s/g, "-");
                const icon = { light: `${fileName}.svg`, dark: `${fileName}.dark.svg` };
                return {
                    title: x
                        .split(".")
                        .at(-1)!
                        .replace(/([a-z])([A-Z])/g, "$1 $2"),
                    component: { type: `Mantine.${x}` },
                    icon,
                };
            } else {
                const fileName = "asset:mantine-icons/" + x.title.replace(/\s/g, "-");
                const icon = { light: `${fileName}.svg`, dark: `${fileName}.dark.svg` };
                x.icon = icon;
                return x;
            }
        }),
    });
}

export function generateId() {
    return (Math.random() + 1).toString(36).substring(7);
}
