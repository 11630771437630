import React, { useMemo } from "react";
import { defaultInputVariant } from "@/inputs/pin-input";
import { registerComponent } from "@/utils";
import {
    classProp,
    disabledProp,
    iconProps,
    inputInteractionProps,
    inputPartProps,
    marginProp,
    placeholderProp,
    radiusProp,
    sizeProp,
    styleProp,
    useVisibleProp,
    visibleProp,
    withErrorStyles,
} from "@/utils/props";
import { DatePickerInput } from "@mantine/dates";
import {
    calendarProps,
    clearableProp,
    dateFormatterProps,
    dateValidationProps,
    dropdownTypeProp,
    useChainCalendarProps,
} from "./date-input";

registerComponent({
    name: "_DatePickerInput",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        defaultInputVariant,
        {
            name: "allowSingleDateInRange",
            type: "boolean",
            description: "Determines whether single year can be selected as range, applicable only when type='range'",
            group: "range",
        },
        {
            name: "closeOnChange",
            type: "boolean",
            description:
                "Determines whether dropdown should be closed when date is selected, not applicable when type='multiple'",
            defaultValue: true,
            group: "behavior",
        },
        dropdownTypeProp,
        {
            name: "type",
            type: "enum",
            options: ["default", "multiple", "range"],
            defaultValue: "default",
            description: "Picker type: range, multiple or default",
            important: true,
        },
        {
            name: "labelSeparator",
            type: "string",
            description: "Separator between range value",
            group: "range",
        },
        ...inputInteractionProps,
        {
            name: "sortDates",
            type: "boolean",
            description:
                "Determines whether dates value should be sorted before onChange call, only applicable when type='multiple'",
            defaultValue: true,
            group: "multiple",
        },
        {
            name: "valueFormatter",
            type: "object",
            description:
                "A function to format selected dates values into a string. By default, date is formatted based on the input type.",
            group: "formatting",
        },
        clearableProp,
        ...inputPartProps,
        ...dateFormatterProps,
        disabledProp,
        ...calendarProps,
        ...iconProps,
        ...dateValidationProps,
        placeholderProp,
        radiusProp,
        sizeProp,
        {
            name: "value",
            type: "object",
            description: "Raw javascript Date value",
            supportsWriteback: true,
            defaultBindingProp: true,
        },
        withErrorStyles,
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, visible, type, ...props } = useChainCalendarProps(properties);
        ref = useVisibleProp(ref, visible);

        let cleanValue = useMemo(() => {
            let cleanValue = value;
            if (type === "multiple") {
                if (value == null) {
                    cleanValue = [];
                } else if (!Array.isArray(value)) {
                    cleanValue = [value];
                }
            } else if (type === "range") {
                if (value == null) {
                    cleanValue = [null, null];
                } else if (!Array.isArray(value)) {
                    cleanValue = [value];
                }
            } else if (Array.isArray(value)) {
                cleanValue = value[0] ?? null;
            }
            return cleanValue;
        }, [value]);

        return <DatePickerInput wrapperProps={{ ref }} value={cleanValue ?? null} type={type} {...props} />;
    },
});
