import React from "react";
import { registerComponent } from "@/utils";
import { classProp, marginProp, paddingProp, styleProp, useVisibleProp, visibleProp } from "@/utils/props";
import {
    useChainHeightWidth,
    useChainMarginStyles,
    useChainPaddingStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { heightProp } from "@/utils/props/dimensions";
import { DropZone, inDesigner } from "@anvil-works/anvil-react/designer";
import { Center } from "@mantine/core";
import { ChildContainer } from "./flex";

registerComponent({
    name: "Center",
    container: true,
    properties: [
        {
            name: "inline",
            type: "boolean",
            description: "Determines whether inline-flex should be used instead of flex, false by default",
            group: "layout",
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
        paddingProp,
        heightProp,
    ],
    layoutProperties: [],
    autoDropZones: false,
    component({ childrenWithLayoutProperties, properties }, ref) {
        const { visible, ...props } = useChainProps(properties, [
            useChainMarginStyles,
            useChainPaddingStyles,
            useChainStyleAndClassName({ overrides: inDesigner ? { minHeight: 45 } : null }),
            useChainHeightWidth,
        ]);

        ref = useVisibleProp(ref, visible);
        const numChildren = childrenWithLayoutProperties.length;
        const initDropStyle = numChildren ? { flexGrow: 0.5, height: 0 } : { flexGrow: 1, margin: 10 };
        const lastStyle = { flexGrow: 0.5, height: 0 };
        return (
            <Center ref={ref} {...props}>
                <DropZone style={initDropStyle} minChildIdx={0} maxChildIdx={0} />
                {childrenWithLayoutProperties.map((c, i) => {
                    return (
                        <ChildContainer key={c.key} child={c} style={i + 1 === numChildren ? lastStyle : undefined} />
                    );
                })}
            </Center>
        );
    },
});
