import React from "react";
import { defaultInputVariant } from "@/inputs/pin-input";
import { registerComponent } from "@/utils";
import {
    disabledProp,
    iconProps,
    inputInteractionProps,
    inputPartProps,
    marginProp,
    override,
    placeholderProp,
    radiusProp,
    sizeProp,
    styleProps,
    useVisibleProp,
    visibleProp,
    withErrorStyles,
} from "@/utils/props";
import { DateTimePicker } from "@mantine/dates";
import {
    calendarProps,
    clearableProp,
    dateFormatterProps,
    dateValidationProps,
    dropdownTypeProp,
    useChainCalendarProps,
} from "./date-input";
import { withSecondsProp } from "./time-input";

registerComponent({
    name: "_DateTimePicker",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        defaultInputVariant,
        clearableProp,
        ...override(dateFormatterProps, { valueFormat: { defaultValue: "DD/MM/YYYY HH:mm" } }),
        ...inputPartProps,
        disabledProp,
        ...calendarProps,
        ...iconProps,
        ...dateValidationProps,
        placeholderProp,
        radiusProp,
        ...inputInteractionProps,
        withErrorStyles,
        sizeProp,
        {
            name: "value",
            type: "object",
            description: "Raw javascript Date value",
            supportsWriteback: true,
            defaultBindingProp: true,
        },
        dropdownTypeProp,
        withSecondsProp,
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, visible, ...props } = useChainCalendarProps(properties);
        ref = useVisibleProp(ref, visible);
        return <DateTimePicker wrapperProps={{ ref }} value={value ?? null} {...props} />;
    },
});
