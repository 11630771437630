import React from "react";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    sizes,
    styleProp,
    useMarginStyles,
    usePlaceholder,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { DropZone, inDesigner, useDropping, useInlineEditRef } from "@anvil-works/anvil-react/designer";
import { Anchor } from "@mantine/core";

registerComponent({
    name: "Anchor",
    events: [{ name: "click", defaultEvent: true }],
    container: true,
    autoDropZones: true,
    properties: [
        {
            name: "color",
            type: "color",
            group: "appearance",
            important: true,
        },
        {
            name: "inherit",
            type: "boolean",
            description: "Determines whether font properties should be inherited from the parent, false by default",
            defaultValue: false,
            group: "typography",
        },
        {
            name: "inline",
            type: "boolean",
            description: "Sets line-height to 1 for centering, false by default",
            defaultValue: false,
            group: "layout",
        },
        // {
        //     name: "lineClamp",
        //     type: "number",
        //     description: "Number of lines after which Text will be truncated",
        //     group: "typography",
        // },
        {
            name: "size",
            type: "enum",
            options: sizes,
            description: "Controls font-size and line-height, 'md' by default",
            defaultValue: "md",
            group: "typography",
        },
        {
            name: "truncate",
            type: "boolean",
            description: "Side on which Text must be truncated, if true, text is truncated from the start",
            group: "typography",
        },
        {
            name: "underline",
            type: "enum",
            options: ["always", "hover", "never"],
            description:
                "Determines in which cases link should have text-decoration: underline styles, hover by default",
            defaultValue: "hover",
            group: "appearance",
        },
        {
            name: "href",
            type: "string",
            description: "The URL that the navigation item links to",
            important: true,
            priority: 10,
            defaultBindingProp: true,
        },
        {
            name: "target",
            type: "enum",
            options: ["_self", "_blank"],
            includeNoneOption: true,
            description: "use _blank to open the link in a new tab",
            important: true,
            priority: 10,
            defaultBindingProp: true,
        },
        { name: "text", type: "string", important: true },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    component({ children, childrenWithoutDropZones, properties }, ref) {
        const { href, visible, text, color, style, className, margin, ...props } = properties;
        const { raiseEvent } = useActions();
        const onClick = (event: React.MouseEvent) => {
            raiseEvent("click", { preventDefault: () => event.preventDefault(), event });
        };
        ref = useVisibleProp(ref, visible);
        const [placeholder, inlineEditOptions, inlineEditing] = usePlaceholder(text);
        const dropping = useDropping();
        const hasChildren = !!childrenWithoutDropZones.length;
        let renderChildren: React.ReactNode = hasChildren ? childrenWithoutDropZones : null;
        if (inDesigner && dropping) {
            renderChildren = hasChildren ? (
                children
            ) : (
                <DropZone style={{ height: 1 }} minChildIdx={0} maxChildIdx={0} />
            );
        }

        let renderPlaceholder = hasChildren ? null : placeholder;
        const textComponent = (
            <span ref={useInlineEditRef("text", null, inlineEditOptions)}>{text || renderPlaceholder}</span>
        );

        return (
            <Anchor
                onClick={onClick}
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                ref={ref}
                href={href || undefined}
                component={href ? "a" : "button"}
                c={color}
                {...props}
                {...useMarginStyles(margin)}>
                {text ? textComponent : hasChildren ? null : textComponent}
                {renderChildren}
            </Anchor>
        );
    },
});

// TODO anchor seems broken with truncate and line clamp because
// lineclamp because of order of classes injected by mantine
// truncate - needs to be inside a particular type of component
// consider containers being inline flex or something
