import { ReactComponentDefinition } from "@anvil-works/anvil-react";

export const variantProp = {
    name: "variant",
    type: "enum",
    options: [],
    important: true,
};

export function variant(options: string[], defaultValue: string) {
    return { ...variantProp, options, defaultValue } as NonNullable<ReactComponentDefinition["properties"]>[number];
}
