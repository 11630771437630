import { useCalendarInteractions, useDateChange, useWeekendDays } from "@/dates/date-input";
import { makeIcon } from "@/icons";
import { useInlineEditInputParts } from "./input";
import { useMarginStyles, usePaddingStyles } from "./spacing";
import { useStyleAndClassName, useStyleObject, useStyleProps } from "./styles";

type Props = Record<string, any>;
/**
 *
 * props = useChain(props, [useChainStyle, useChainMargin, useChainInputParts])
 */
export function useChainProps(props: Props, chain: ((p: Props) => Props)[]) {
    let rv = props;
    for (const hook of chain) {
        rv = hook(rv);
    }
    return rv;
}

export function useChainStyle(props: Props) {
    const { style } = props;
    return { ...props, style: useStyleObject(style) };
}

export function useChainInputParts(props: Props) {
    return { ...props, ...useInlineEditInputParts(props, false) };
}

export function useChainIconProps(props: Props) {
    const { iconLeft, iconRight, ...restProps } = props;
    return { ...restProps, leftSection: makeIcon(iconLeft), rightSection: makeIcon(iconRight) };
}

export function useChainCalendarInteractions(props: Props) {
    return { ...props, ...useCalendarInteractions() };
}

export function useChainWeekendDays(props: Props) {
    return { ...props, weekendDays: useWeekendDays(props.weekendDays) };
}

export function useChainMarginStyles(props: Props) {
    const { margin, ...restProps } = props;
    return { ...restProps, ...useMarginStyles(margin) };
}

export function useChainPaddingStyles(props: Props) {
    const { padding, ...restProps } = props;
    return { ...restProps, ...usePaddingStyles(padding) };
}

export function useChainDateChange(props: Props) {
    return { ...props, onChange: useDateChange() };
}

export function useChainStyleAndClassName({
    name = "root",
    overrides,
}: {
    name?: string;
    overrides?: React.CSSProperties | null;
} = {}) {
    if (typeof name !== "string") {
        throw new Error("useChainStyleAndClassName must be called");
    }
    return (props: Props) => {
        const { style, className, ...restProps } = props;
        return { ...restProps, ...useStyleAndClassName(style, className, { name, overrides }) };
    };
}

export function useChainHeightWidth({ height, width, ...props }: Props) {
    return { ...props, h: height, w: width };
}

export function useChainStyleProps({ styleProps, ...props }: Props) {
    return { ...props, ...useStyleProps(styleProps) };
}
