import React from "react";
import { registerComponent } from "@/utils";
import {
    autoContrast,
    colorProp,
    compactSizes,
    disabledProp,
    iconProps,
    justifyProp,
    marginProp,
    override,
    radiusProp,
    sizeProp,
    styleProps,
    usePlaceholder,
    useVisibleProp,
    variant,
    visibleProp,
} from "@/utils/props";
import { useChainIconProps, useChainMarginStyles, useChainProps, useChainStyleAndClassName } from "@/utils/props/chain";
import { PropertyDescription, useActions } from "@anvil-works/anvil-react";
import { useInlineEditRef } from "@anvil-works/anvil-react/designer";
import { Button } from "@mantine/core";

export const buttonProps = [
    autoContrast,
    variant(["default", "filled", "light", "outline", "subtle", "transparent", "white"], "filled"),
    disabledProp,
    {
        name: "inline",
        type: "boolean",
        group: "appearance",
        description: "Determines whether the button is displayed inline or as a block element",
    },
    {
        name: "fullWidth",
        type: "boolean",
        group: "appearance",
        description: "Determines whether button should take 100% width of its parent container, false by default",
    },
    {
        name: "loading",
        type: "boolean",
        group: "interaction",
        description: "Determines whether the Loader component should be displayed over the button",
    },
    ...iconProps,
    ...override([justifyProp, colorProp, sizeProp, radiusProp], {
        size: {
            options: compactSizes,
            description: "Controls button height, font-size and horizontal padding, 'sm' by default",
        },
        color: { description: "Key of theme.colors or any valid CSS color, theme.primaryColor by default" },
        justify: {
            group: "appearance",
            description:
                "Sets justify-content of inner element, can be used to change distribution of sections and label, 'center' by default",
        },
    }),
    { name: "text", type: "string", important: true },
    ...styleProps,
    marginProp,
    visibleProp,
] satisfies (PropertyDescription & { defaultValue?: any })[];

export const useButtonProps = ({ visible, inline, ...props }: any, ref: any) => {
    const { raiseEvent } = useActions();
    const onClick = () => raiseEvent("click");
    props = useChainProps(props, [
        useChainStyleAndClassName({ overrides: !inline ? { display: "block" } : null }),
        useChainIconProps,
        useChainMarginStyles,
    ]);
    ref = useVisibleProp(ref, visible);
    return [{ onClick, ...props }, ref];
};

export function useMantineButton(properties: any, ref_?: any) {
    const [props, ref] = useButtonProps(properties, ref_);
    const [placeholder, inlineEditOptions] = usePlaceholder(props.text);
    return (
        <Button ref={ref} {...props}>
            <span ref={useInlineEditRef("text", null, inlineEditOptions)}>{props.text || placeholder}</span>
        </Button>
    );
}

registerComponent({
    name: "Button",
    events: [{ name: "click", defaultEvent: true }],
    properties: buttonProps,
    component: ({ properties }, ref) => {
        return useMantineButton(properties, ref);
    },
});
