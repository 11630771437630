import { useSyncExternalStore } from "react";

type Listener = () => void;

interface StoreState {}

export class ExternalStore<S extends StoreState> {
    private _listeners = new Set<Listener>();
    subscribe = (listener: () => void) => {
        this._listeners.add(listener);
        return () => {
            this._listeners.delete(listener);
        };
    };
    constructor(private _state: S) {}
    getState() {
        return this._state;
    }
    setState(update: Partial<S> | ((prev: S) => Partial<S>)) {
        if (typeof update === "function") {
            update = update(this._state);
        }
        // we can mutate the state here because a re-render
        // depends on the snapshot used in useSyncExternalStore
        // if we want the store state to be a new object each time do: Object.assign({}, this._state, update);
        this._state = Object.assign({}, this._state, update);
        this._listeners.forEach((l) => l());
    }
}

export function useStoreSelector<S extends StoreState, R = S>(store: ExternalStore<S>, selector: (state: S) => R) {
    return useSyncExternalStore(store.subscribe, () => selector(store.getState()));
}

export function useStoreState<S extends StoreState, R = S>(store: ExternalStore<S>) {
    return useSyncExternalStore(store.subscribe, () => store.getState());
}
