import { useMemo } from "react";
import { propertyUtils } from "@anvil-works/anvil-react";

export const spacingProp = {
    name: "spacing",
    type: "spacing",
    group: "spacing",
    important: true,
} as const;

export const marginProp = {
    name: "margin",
    type: "margin",
    group: "spacing",
    defaultValue: "",
    description: "Supports any css value, or mantine spacing value: xs, sm, md, lg, xl",
} as const;

export const paddingProp = {
    name: "padding",
    type: "padding",
    group: "spacing",
    defaultValue: "",
    description: "Supports any css value, or mantine spacing value: xs, sm, md, lg, xl",
} as const;

const marginToMantine = {
    marginTop: "mt",
    marginBottom: "mb",
    marginLeft: "ml",
    marginRight: "mr",
} as const;

const paddingToMantine = {
    paddingTop: "pt",
    paddingBottom: "pb",
    paddingLeft: "pl",
    paddingRight: "pr",
} as const;

export function getMarginStyles(margin: any) {
    const marginStyles = propertyUtils.getMarginStyles(margin);
    const mantineMargineStyles = {} as { mt: any; mb: any; ml: any; mr: any };
    for (const ms in marginStyles) {
        const val = marginStyles[ms];
        if (val === "" || val == null) continue;
        const key = marginToMantine[ms as keyof typeof marginToMantine];
        mantineMargineStyles[key] = val;
    }
    return mantineMargineStyles;
}

export function useMarginStyles(margin: any) {
    return useMemo(() => getMarginStyles(margin), [margin]);
}

export function getPaddingStyles(margin: any) {
    const paddingStyles = propertyUtils.getPaddingStyles(margin);
    const mantinePaddingStyles = {} as { pt: any; pb: any; pl: any; pr: any };
    for (const ps in paddingStyles) {
        const val = paddingStyles[ps];
        if (val === "" || val == null) continue;
        const key = paddingToMantine[ps as keyof typeof paddingToMantine];
        mantinePaddingStyles[key] = val;
    }
    return mantinePaddingStyles;
}

export function usePaddingStyles(padding: any) {
    return useMemo(() => getPaddingStyles(padding), [padding]);
}
