import React from "react";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    paddingProp,
    sizesWithNone,
    sizesWithZero,
    styleProp,
    visibleProp,
} from "@/utils/props";
import {
    useChainMarginStyles,
    useChainPaddingStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { ChildWithLayoutProperties, ReactComponentDefinition } from "@anvil-works/anvil-react";
import { DropZone, inDesigner } from "@anvil-works/anvil-react/designer";
import { Flex } from "@mantine/core";

// export const ChildContainer = ({ child, style }: { child: ChildWithLayoutProperties; style?: React.CSSProperties }) => {
//     const divRef = useSetVisibility(null, child.visible);
//     style ??= { alignSelf: "stretch", flexGrow: 0 };

//     return (
//         <React.Fragment>
//             <Box ref={divRef} style={{ display: "inline-flex", ...child.layoutProperties }} key={child.key}>
//                 {child.child}
//             </Box>
//             <DropZone style={style} minChildIdx={child.childIdx + 1} maxChildIdx={child.childIdx + 1} />
//         </React.Fragment>
//     );
// };

export const ChildContainer = ({ child, style }: { child: ChildWithLayoutProperties; style?: React.CSSProperties }) => {
    return (
        <React.Fragment>
            {child.child}
            <DropZone style={style} minChildIdx={child.childIdx + 1} maxChildIdx={child.childIdx + 1} />
        </React.Fragment>
    );
};

export const flexProperties = {
    align: {
        name: "align",
        type: "enum",
        options: ["stretch", "center", "flex-start", "flex-end", "baseline"],
        includeNoneOption: true,
        description: "align-items CSS property",
        group: "layout",
    },
    // columnGap: {
    //     name: "columnGap",
    //     type: "enum",
    //     options: sizes,
    //     description: "column-gap CSS property",
    //     group: "spacing",
    // },
    direction: {
        name: "direction",
        type: "enum",
        options: ["row", "row-reverse", "column", "column-reverse"],
        defaultValue: "row",
        description: "flex-direction CSS property",
        group: "layout",
    },
    gap: {
        name: "gap",
        type: "enum",
        options: sizesWithZero,
        includeNoneOption: true,
        description: "gap CSS property",
        group: "spacing",
    },
    justify: {
        name: "justify",
        type: "enum",
        options: [
            "stretch",
            "flex-start",
            "flex-end",
            "center",
            "normal",
            "space-between",
            "space-around",
            "space-evenly",
        ],
        description: "justify-content CSS property",
        includeNoneOption: true,
        group: "layout",
    },
    // rowGap: {
    //     name: "rowGap",
    //     type: "enum",
    //     options: sizes,
    //     includeNoneOption: true,
    //     description: "row-gap CSS property",
    //     group: "spacing",
    // },
    wrap: {
        name: "wrap",
        type: "enum",
        options: ["nowrap", "wrap", "wrap-reverse"],
        includeNoneOption: true,
        description: "flex-wrap CSS property",
        group: "layout",
    },
} satisfies Record<string, NonNullable<ReactComponentDefinition["properties"]>[number]>;

export function pick<T extends object, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
    const result: Pick<T, K> = {} as Pick<T, K>;
    keys.forEach((key) => {
        if (key in obj) {
            result[key] = obj[key];
        }
    });
    return result;
}

registerComponent({
    name: "Flex",
    container: true,
    properties: [...Object.values(flexProperties), visibleProp, styleProp, classProp, marginProp, paddingProp],
    layoutProperties: [],
    autoDropZones: false,
    component({ childrenWithLayoutProperties, properties }, ref) {
        const { visible, ...props } = useChainProps(properties, [
            useChainMarginStyles,
            useChainPaddingStyles,
            useChainStyleAndClassName({ overrides: inDesigner ? { minHeight: 45 } : null }),
        ]);
        const numChildren = childrenWithLayoutProperties.length;
        const initDropStyle = numChildren ? { alignSelf: "stretch", flexGrow: 0 } : { flexGrow: 1, margin: 10 };
        const { direction } = props;
        const horizontal = direction === "row" || direction === "row-reverse";
        let lastStyle: undefined | React.CSSProperties = undefined;
        if (horizontal) {
            lastStyle = { flexGrow: 1, height: 0, alignSelf: "center" };
        }

        return (
            <Flex ref={ref} {...props}>
                <DropZone style={initDropStyle} minChildIdx={0} maxChildIdx={0} />
                {childrenWithLayoutProperties.map((c, i) => {
                    return (
                        <ChildContainer key={c.key} child={c} style={i + 1 === numChildren ? lastStyle : undefined} />
                    );
                })}
            </Flex>
        );
    },
});
