/** @todo - we'll probably want to create this as a separate repo */
import React from "react";
import { registerSection } from "@/utils";
import { ExternalStore, useStoreState } from "@/utils/external-store";
import { includeContext } from "@anvil-works/anvil-react";
import { DatesProvider, DayOfWeek } from "@mantine/dates";
import "@mantine/dates/styles.css";
import "./date-input";
import "./date-picker-input";
import "./date-time-picker";
import "./time-input";

const dateStore = new ExternalStore<{
    locale?: string;
    firstDayOfWeek: DayOfWeek;
    weekendDays: DayOfWeek[];
    timezone?: string;
}>({ locale: "en", firstDayOfWeek: 1, weekendDays: [0, 6] });

// @ts-expect-error
window.anvilMantine ??= {};
// @ts-expect-error
Object.assign(window.anvilMantine, { dateStore });

function DateProviderWrapper({ children }: React.PropsWithChildren) {
    const settings = useStoreState(dateStore);
    return <DatesProvider settings={settings}>{children}</DatesProvider>;
}

includeContext(DateProviderWrapper);

registerSection("Dates", ["DateInput", "DatePickerInput", "DateTimePicker", "TimeInput"]);
