import React from "react";
import { registerComponent } from "@/utils";
import {
    colorProp,
    disabledProp,
    marginProp,
    override,
    radiusProp,
    sizeProp,
    styleProps,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { Slider } from "@mantine/core";

registerComponent({
    name: "Slider",
    events: [{ name: "change", defaultEvent: true }, { name: "change_end" }],
    properties: [
        colorProp,
        disabledProp,
        {
            name: "inverted",
            type: "boolean",
            description: "Determines whether track value representation should be inverted",
            defaultValue: false,
            group: "slider",
        },
        {
            name: "label",
            type: "string",
            description: "string or function to generate label given a value, set to None to disable label",
            group: "label",
        },
        {
            name: "labelAlwaysOn",
            type: "boolean",
            description:
                "Determines whether the label should be visible when the slider is not being dragged or hovered",
            defaultValue: false,
            group: "label",
        },
        {
            name: "marks",
            type: "object",
            description: "Marks displayed on the track",
            group: "slider",
        },
        {
            name: "max",
            type: "number",
            description: "Maximum possible value",
            defaultValue: 100,
            group: "range",
        },
        {
            name: "min",
            type: "number",
            description: "Minimal possible value",
            defaultValue: 0,
            group: "range",
        },
        {
            name: "precision",
            type: "number",
            description: "Number of significant digits after the decimal point",
            group: "range",
        },
        ...override([radiusProp, sizeProp], { radius: { defaultValue: "xl" }, size: { defaultValue: "md" } }),
        {
            name: "scale",
            type: "object",
            description: "A transformation function to change the scale of the slider",
            group: "slider",
        },
        {
            name: "showLabelOnHover",
            type: "boolean",
            description: "Determines whether the label should be displayed when the slider is hovered",
            defaultValue: true,
            group: "label",
        },
        {
            name: "step",
            type: "number",
            description: "Number by which value will be incremented/decremented with thumb drag and arrows",
            defaultValue: 1,
            group: "range",
        },
        {
            name: "thumbLabel",
            type: "string",
            description: "Thumb aria-label",
            group: "thumb",
        },
        {
            name: "thumbSize",
            type: "string",
            description: "Thumb width and height, by default value is computed based on size prop",
            group: "thumb",
        },
        {
            name: "value",
            type: "number",
            important: true,
            priority: 10,
            defaultBindingProp: true,
            supportsWriteback: true,
        },
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, visible, style, className, margin, ...props } = properties;
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const onChange = async (value: number) => {
            setProperty("value", value);
            raiseEvent("change");
        };
        const onChangeEnd = async (value: number) => {
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("change_end");
            }
        };
        ref = useVisibleProp(ref, visible);
        return (
            <Slider
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                value={value ?? props.min ?? ""}
                ref={ref}
                onChange={onChange}
                onChangeEnd={onChangeEnd}
                {...props}
                {...useMarginStyles(margin)}
            />
        );
    },
});
