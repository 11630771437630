import React from "react";
import { registerComponent } from "@/utils";
import {
    classProp,
    colorProp,
    inputInteractionProps,
    marginProp,
    override,
    pick,
    sizeProp,
    sizes,
    styleProp,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import useEvent from "@/utils/use-event";
import { useActions } from "@anvil-works/anvil-react";
import { Rating } from "@mantine/core";

// TODO - consider whether to implement empty/full symbol
// see React Toast which does clever things around getting the .reactComponent()

registerComponent({
    name: "Rating",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        ...override([colorProp], { color: { defaultValue: "yellow" } }),
        {
            name: "count",
            type: "number",
            description: "Number of controls",
            defaultValue: 5,
            group: "rating",
        },
        {
            name: "fractions",
            type: "number",
            description: "Number of fractions each item can be divided into",
            defaultValue: 1,
            group: "rating",
        },
        // {
        //     name: "fullSymbol",
        //     type: "icon",
        //     description: "Icon displayed when the symbol is full",
        //     group: "icon",
        // },
        // {
        //     name: "getSymbolLabel",
        //     type: "object",
        //     description:
        //         "A function to assign aria-label of the control at index given in the argument. If not specified, control index is used as aria-label.",
        //     group: "accessibility",
        // },
        {
            name: "highlightSelectedOnly",
            type: "boolean",
            description: "If set, only the selected symbol changes to full symbol when selected",
            defaultValue: false,
            group: "interaction",
        },
        // {
        //     name: "name",
        //     type: "string",
        //     description: "name attribute passed down to all inputs. By default, name is generated randomly.",
        //     group: "input",
        // },
        // {
        //     name: "onChange",
        //     type: "((value: number) => void)",
        //     description: "Called when value changes",
        //     group: "event",
        // },
        // {
        //     name: "onHover",
        //     type: "((value: number) => void)",
        //     description: "Called when one of the controls is hovered",
        //     group: "event",
        // },
        ...pick(inputInteractionProps, ["readonly"]),
        sizeProp,
        {
            name: "value",
            type: "number",
            description: "Value for controlled component",
            important: true,
            defaultBindingProp: true,
            supportsWriteback: true,
            priority: 10,
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, visible, style, className, margin, ...props } = properties;
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const onChange = useEvent(async (value: number) => {
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("change");
            }
        });

        ref = useVisibleProp(ref, visible);

        return (
            <Rating
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                value={value ?? ""}
                onChange={onChange}
                ref={ref}
                {...props}
                {...useMarginStyles(margin)}
            />
        );
    },
});
