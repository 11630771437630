import React from "react";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    sizesWithNone,
    styleProp,
    useMarginStyles,
    useSetVisibility,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { ChildWithLayoutProperties } from "@anvil-works/anvil-react";
import { DropZone, inDesigner, useDesignerApi, useDropping } from "@anvil-works/anvil-react/designer";
import { Group, SimpleGrid } from "@mantine/core";

interface ChildContainerProps {
    child: ChildWithLayoutProperties;
    style?: React.CSSProperties;
    before?: React.ReactElement;
    after?: React.ReactElement;
}

export const ChildContainer = ({ child, before, after }: ChildContainerProps) => {
    const divRef = useSetVisibility(null, child.visible);
    const isDropping = useDropping() || false;
    if (!inDesigner || !isDropping) {
        return child.child;
    }
    return (
        <Group wrap="nowrap" justify="center" ref={divRef} key={child.key}>
            {before}
            {child.child}
            {after}
        </Group>
    );
};

registerComponent({
    name: "SimpleGrid",
    container: true,
    properties: [
        { name: "cols", type: "number", important: true },
        {
            name: "spacing",
            type: "enum",
            options: sizesWithNone,
            includeNoneOption: true,
            description: "gap CSS property",
            group: "spacing",
        },
        {
            name: "verticalSpacing",
            type: "enum",
            options: sizesWithNone,
            includeNoneOption: true,
            description: "gap CSS property",
            group: "spacing",
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    layoutProperties: [],
    autoDropZones: false,
    component({ childrenWithLayoutProperties, properties: { visible, style, className, margin, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);
        if (inDesigner) {
            styleObject.minHeight = 20;
        }
        const numChildren = childrenWithLayoutProperties.length;
        const cols = props.cols || 1;
        const dzs = childrenWithLayoutProperties.map((_, idx) => {
            const row = Math.floor(idx / cols) + 1;
            const col = (idx % cols) + 1;
            return (
                <React.Fragment>
                    <DropZone
                        key={`${row}-${col}-left`}
                        style={{
                            gridArea: `${row} / ${col} / ${row + 1} / ${col + 1}`,
                            marginLeft: "auto",
                            marginRight: -5,
                            height: "100%",
                        }}
                        minChildIdx={idx}
                        maxChildIdx={idx}
                    />
                    <DropZone
                        key={`${row}-${col}-right`}
                        style={{
                            gridArea: `${row} / ${col} / ${row + 1} / ${col + 1}`,
                            marginRight: "auto",
                            marginLeft: -5,
                            height: "100%",
                        }}
                        minChildIdx={idx + 1}
                        maxChildIdx={idx + 1}
                    />
                </React.Fragment>
            );
        });

        return (
            <SimpleGrid
                ref={ref}
                styles={{ root: styleObject }}
                classNames={{ root: className }}
                {...props}
                {...marginStyles}>
                <DropZone style={{ gridColumn: "1 / -1" }} minChildIdx={0} maxChildIdx={0} />
                {childrenWithLayoutProperties.map((c, i) => {
                    return (
                        <ChildContainer
                            key={c.key}
                            child={c}
                            // style={i + 1 === numChildren ? lastStyle : undefined}
                            before={
                                <DropZone
                                    style={{ alignSelf: "stretch" }}
                                    minChildIdx={c.childIdx}
                                    maxChildIdx={c.childIdx}
                                />
                            }
                            after={
                                <DropZone
                                    style={{ alignSelf: "stretch" }}
                                    minChildIdx={c.childIdx + 1}
                                    maxChildIdx={c.childIdx + 1}
                                />
                            }
                        />
                    );
                })}
                {numChildren ? (
                    <DropZone style={{ gridColumn: "1 / -1" }} minChildIdx={numChildren} maxChildIdx={numChildren} />
                ) : null}
            </SimpleGrid>
        );
    },
});
