import React from "react";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    sizesWithZero,
    styleProp,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { inDesigner } from "@anvil-works/anvil-react/designer";
import { BackgroundImage } from "@mantine/core";

registerComponent({
    name: "BackgroundImage",
    container: true,
    autoDropZoneProps: true,
    properties: [
        {
            name: "radius",
            type: "enum",
            options: sizesWithZero,
            description: "Key of theme.radius or any valid CSS value to set border-radius, 0 by default",
            defaultValue: "0",
            group: "appearance",
        },

        {
            name: "src",
            type: "uri",
            accept: "image/*",
            description: "Image url",
            important: true,
        },
        classProp,
        marginProp,
        styleProp,
        visibleProp,
    ],
    layoutProperties: [],
    component({ children, properties: { visible, src, style, className, margin, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        if (inDesigner) {
            src ||= "https://placehold.co/600x400?text=BackgroundImage";
        }
        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);
        return (
            <BackgroundImage
                src={src}
                ref={ref}
                styles={{ root: styleObject }}
                classNames={{ root: className }}
                {...props}
                {...marginStyles}>
                {children}
            </BackgroundImage>
        );
    },
});
