import React from "react";
import { ExternalStore, useStoreSelector } from "./external-store";

class ModalStore extends ExternalStore<{ value: any[] }> {
    add(M: any) {
        this.setState(({ value }) => {
            if (value.includes(M)) return { value };
            return { value: [...value, M] };
        });
    }
    remove(M: any) {
        this.setState(({ value }) => {
            return { value: value.filter((v) => v !== M) };
        });
    }
}

export const modalStore = new ModalStore({ value: [] });

export const ModalProvider = ({ children }: React.PropsWithChildren) => {
    const modals = useStoreSelector(modalStore, (s) => s.value);
    return (
        <React.Fragment>
            {children}
            {modals.map((m, i) => (
                <React.Fragment key={i}>{m}</React.Fragment>
            ))}
        </React.Fragment>
    );
};
