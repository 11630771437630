import React from "react";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    sizesWithNone,
    styleProp,
    useMarginStyles,
    useSetVisibility,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { ChildWithLayoutProperties } from "@anvil-works/anvil-react";
import { DropZone, inDesigner, useDesignerApi } from "@anvil-works/anvil-react/designer";
import { Grid } from "@mantine/core";
import { flexProperties, pick } from "./flex";

interface ChildContainerProps {
    child: ChildWithLayoutProperties;
    style?: React.CSSProperties;
    before?: React.ReactElement;
    after?: React.ReactElement;
}

export const ChildContainer = ({ child, before, after }: ChildContainerProps) => {
    const divRef = useSetVisibility(null, child.visible);
    return (
        <Grid.Col ref={divRef} {...child.layoutProperties} key={child.key}>
            {before}
            {child.child}
            {after}
        </Grid.Col>
    );
};

registerComponent({
    name: "Grid",
    container: true,
    properties: [
        { name: "columns", type: "number", description: "Number of columns in each row, 12 by default" },
        ...Object.values(pick(flexProperties, ["align", "justify"])),
        {
            name: "grow",
            type: "boolean",
            description: "Determines whether each child element should have flex-grow: 1 style, false by default",
            group: "layout",
        },
        {
            name: "overflow",
            type: "enum",
            options: ["visible", "clip", "hidden"],
            defaultValue: "visible",
            group: "layout",
        },
        {
            name: "gutter",
            type: "enum",
            options: sizesWithNone,
            includeNoneOption: true,
            description: "gap CSS property",
            group: "spacing",
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    layoutProperties: [
        { name: "offset", type: "string" },
        { name: "span", type: "string" },
    ],
    autoDropZones: false,
    component(
        { childrenWithLayoutProperties, properties: { visible, columns, style, className, margin, ...props } },
        ref
    ) {
        ref = useVisibleProp(ref, visible);
        columns ??= 12;
        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);
        if (inDesigner) {
            styleObject.minHeight = 20;
        }
        const numChildren = childrenWithLayoutProperties.length;

        return (
            <Grid
                ref={ref}
                styles={{ root: styleObject }}
                classNames={{ root: className }}
                columns={columns}
                {...props}
                {...marginStyles}>
                {inDesigner && (
                    <Grid.Col span={columns}>
                        <DropZone minChildIdx={0} maxChildIdx={0} />
                    </Grid.Col>
                )}

                {childrenWithLayoutProperties.map((c, i) => {
                    c.layoutProperties.offset ||= undefined;
                    c.layoutProperties.span ||= undefined;
                    return (
                        <ChildContainer
                            key={c.key}
                            child={c}
                            before={
                                <DropZone
                                    style={{ alignSelf: "stretch" }}
                                    minChildIdx={c.childIdx}
                                    maxChildIdx={c.childIdx}
                                    layoutProperties={{ ...c.layoutProperties }}
                                />
                            }
                            after={
                                <DropZone
                                    style={{ alignSelf: "stretch" }}
                                    minChildIdx={c.childIdx + 1}
                                    maxChildIdx={c.childIdx + 1}
                                    layoutProperties={{ ...c.layoutProperties }}
                                />
                            }
                        />
                    );
                })}
                {numChildren && inDesigner ? (
                    <Grid.Col span={columns}>
                        <DropZone minChildIdx={numChildren} maxChildIdx={numChildren} />
                    </Grid.Col>
                ) : null}
            </Grid>
        );
    },
});
