import React from "react";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    sizes,
    sizesWithNone,
    styleProp,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { inDesigner, useDesignerApi } from "@anvil-works/anvil-react/designer";
import { Card } from "@mantine/core";

registerComponent({
    name: "Card",
    container: true,
    properties: [
        {
            name: "padding",
            type: "enum",
            options: sizesWithNone,
            description: "Controls padding, key of theme.spacing or any valid CSS value, 'md' by default",
            defaultValue: "md",
            group: "layout",
        },
        {
            name: "radius",
            type: "enum",
            options: sizes,
            description:
                "Key of theme.radius or any valid CSS value to set border-radius, numbers are converted to rem, theme.defaultRadius by default",
            defaultValue: "theme.defaultRadius",
            group: "appearance",
        },
        {
            name: "shadow",
            type: "enum",
            options: sizesWithNone,
            description: "Key of theme.shadows or any valid CSS value to set box-shadow, none by default",
            defaultValue: "none",
            group: "appearance",
        },
        {
            name: "withBorder",
            type: "boolean",
            description:
                "Determines whether the card should have border, border color depends on color scheme, false by default",
            defaultValue: false,
            group: "appearance",
        },
        visibleProp,
        classProp,
        marginProp,
        styleProp,
    ],
    layoutProperties: [],
    autoDropZones: true,
    component({ children, properties: { visible, style, className, margin, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);
        if (inDesigner) {
            styleObject.minHeight = 20;
        }
        return (
            <Card
                ref={ref}
                styles={{ root: styleObject }}
                classNames={{ root: className }}
                {...props}
                {...marginStyles}>
                {children}
            </Card>
        );
    },
});

registerComponent({
    name: "CardSection",
    container: true,
    properties: [
        {
            name: "inheritPadding",
            type: "boolean",
            description: "Determines whether the section should inherit padding from the parent Card, false by default",
            defaultValue: false,
            group: "layout",
        },
        {
            name: "withBorder",
            type: "boolean",
            description: "Determines whether the section should have a border, false by default",
            defaultValue: false,
            group: "appearance",
        },
        visibleProp,
        classProp,
        marginProp,
        styleProp,
    ],
    layoutProperties: [],
    autoDropZones: true,
    component({ children, properties: { visible, style, className, margin, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);
        if (inDesigner) {
            styleObject.minHeight = 20;
        }
        return (
            <Card.Section
                ref={ref}
                styles={{ section: styleObject }}
                classNames={{ section: className }}
                {...props}
                {...marginStyles}>
                {children}
            </Card.Section>
        );
    },
});
