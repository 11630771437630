import React from "react";
import { registerComponent } from "@/utils";
import { classProp, marginProp, sizesWithZero, styleProp, useVisibleProp, visibleProp } from "@/utils/props";
import {
    useChainHeightWidth,
    useChainMarginStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { inDesigner } from "@anvil-works/anvil-react/designer";
import { Image } from "@mantine/core";

registerComponent({
    name: "Image",
    properties: [
        { name: "height", type: "string", group: "layout" },
        { name: "width", type: "string", group: "layout" },
        {
            name: "fallbackSrc",
            type: "string",
            description:
                "Image url that will be used as a fallback in case src prop is not set or image cannot be loaded",
            group: "behavior",
        },
        {
            name: "fit",
            type: "enum",
            options: ["cover", "contain", "fill", "none", "scale-down"],
            description: "Controls object-fit style, 'cover' by default",
            defaultValue: "cover",
            group: "appearance",
        },
        {
            name: "radius",
            type: "enum",
            options: sizesWithZero,
            description: "Key of theme.radius or any valid CSS value to set border-radius, 0 by default",
            defaultValue: "0",
            group: "appearance",
        },
        {
            name: "alt",
            type: "string",
            important: true,
        },
        {
            name: "src",
            type: "uri",
            accept: "image/*",
            description: "Image url",
            important: true,
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    layoutProperties: [],
    component({ properties }, ref) {
        const { visible, ...props } = useChainProps(properties, [
            useChainMarginStyles,
            useChainStyleAndClassName(),
            useChainHeightWidth,
        ]);
        ref = useVisibleProp(ref, visible);
        if (inDesigner) {
            props.fallbackSrc ||= "https://placehold.co/600x400?text=Image";
        }
        return <Image ref={ref} {...props} />;
    },
});
