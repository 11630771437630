import React, { useMemo, useRef } from "react";
import { makeIcon } from "@/icons";
import { defaultInputVariant } from "@/inputs/pin-input";
import { registerComponent } from "@/utils";
import {
    disabledProp,
    iconProps,
    inputInteractionProps,
    inputPartProps,
    inputPointerProp,
    marginProp,
    radiusProp,
    sizeProp,
    styleProps,
    useVisibleProp,
    visibleProp,
    withErrorStyles,
} from "@/utils/props";
import {
    useChainIconProps,
    useChainInputParts,
    useChainMarginStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { useActions } from "@anvil-works/anvil-react";
import { useInlineEditRef, useRegionInteractionRef } from "@anvil-works/anvil-react/designer";
import { ActionIcon } from "@mantine/core";
import { TimeInput } from "@mantine/dates";

export const withSecondsProp = {
    name: "withSeconds",
    type: "boolean",
    description: "Determines whether seconds input should be rendered",
    group: "time",
} as const;

registerComponent({
    name: "_TimeInput",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        defaultInputVariant,
        disabledProp,
        ...inputPartProps,
        {
            name: "maxTime",
            type: "string",
            description:
                "Maximum possible string time, if withSeconds is true, time should be in format HH:mm:ss, otherwise HH:mm",
            group: "time",
        },
        {
            name: "minTime",
            type: "string",
            description:
                "Minimum possible string time, if withSeconds is true, time should be in format HH:mm:ss, otherwise HH:mm",
            group: "time",
        },
        radiusProp,
        inputPointerProp,
        ...iconProps,
        sizeProp,
        ...inputInteractionProps,
        withErrorStyles,
        {
            name: "showPicker",
            type: "boolean",
            description:
                "Determines whether a time icon can be used to show browser's default time picker. Not supported by all browsers",
            group: "time",
        },
        withSecondsProp,
        {
            name: "value",
            type: "string",
            description: "String value representation of the time",
            important: true,
            supportsWriteback: true,
            defaultBindingProp: true,
            priority: 10,
        },
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties }, ref) {
        const {
            value,
            visible,
            rightSection: rightSectionIcon,
            showPicker,
            ...props
        } = useChainProps(properties, [
            useChainIconProps,
            useChainStyleAndClassName(),
            useChainInputParts,
            useChainMarginStyles,
        ]);

        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("change");
            }
        };

        ref = useVisibleProp(ref, visible);

        const inputRef = useRef<HTMLInputElement>(null);
        const showNativePicker = () => {
            try {
                inputRef.current?.showPicker();
            } catch (e) {
                console.warn(e);
            }
        };
        // probably has no effect - at least in chrome - gives an error: showPicker() called from cross-origin iframe
        const actionRef = useRegionInteractionRef<HTMLButtonElement>(showNativePicker);
        const rightSection = useMemo(() => {
            if (showPicker) {
                return (
                    <ActionIcon ref={actionRef} variant="subtle" color="gray" onClick={showNativePicker}>
                        {rightSectionIcon || makeIcon("fa:clock-o")}
                    </ActionIcon>
                );
            }
            return rightSectionIcon;
        }, [showPicker, rightSectionIcon]);

        return (
            <TimeInput
                wrapperProps={{ ref }}
                ref={useInlineEditRef("value", inputRef)}
                rightSection={rightSection}
                value={value ?? ""}
                onChange={onChange}
                {...props}
            />
        );
    },
});
