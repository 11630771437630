import { useEffect } from "react";
import { MantineColorScheme, localStorageColorSchemeManager } from "@mantine/core";
import "@mantine/core/styles.css";
import { ExternalStore, useStoreSelector } from "./external-store";

export const colorSchemeManager = localStorageColorSchemeManager();
export const colorSchemeStore = new ExternalStore<{ value: MantineColorScheme }>({
    value: colorSchemeManager.get("light"),
});
colorSchemeManager.get = () => "auto";

export const useColorScheme = () => {
    const value = useStoreSelector(colorSchemeStore, (s) => s.value);

    useEffect(() => {
        colorSchemeManager.set(value);
    }, [value]);

    return {
        forceColorScheme: value === "auto" ? undefined : value,
        colorSchemeManager,
    } as const;
};
