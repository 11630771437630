import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    styleProp,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { MultiSelect } from "@mantine/core";
import { comboboxProps, commonSelectProps } from "./utils";

const EmptyArray: string[] = [];
const data = ["foo", "bar", "baz"];

registerComponent({
    name: "MultiSelect",
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        ...comboboxProps,
        ...commonSelectProps,
        {
            name: "hidePickedOptions",
            type: "boolean",
            description: "Determines whether picked options should be removed from the options list, false by default",
            defaultValue: false,
        },
        {
            name: "maxValues",
            type: "number",
            description: "Maximum number of values, Infinity by default",
            // defaultValue: "Infinity",
        },

        {
            name: "value",
            type: "text[]",
            description: "Controlled component value",
            important: true,
            priority: 10,
            defaultBindingProp: true,
            supportsWriteback: true,
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, visible, style, margin, className, iconLeft, iconRight, ...props } = properties;
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const onChange = async (value: string[]) => {
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("change");
            }
        };
        ref = useVisibleProp(ref, visible);
        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);

        return (
            <MultiSelect
                wrapperProps={{ ref }}
                data={data}
                styles={{ root: styleObject }}
                classNames={{ root: className }}
                leftSection={makeIcon(iconLeft)}
                rightSection={makeIcon(iconRight)}
                value={value ?? EmptyArray}
                onChange={onChange}
                {...props}
                {...marginStyles}
            />
        );
    },
});
