import React from "react";
import { registerComponent } from "@/utils";
import { radiusProp, sizeProp, styleProps, useVisibleProp, visibleProp } from "@/utils/props";
import {
    useChainIconProps,
    useChainMarginStyles,
    useChainPaddingStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { Progress } from "@mantine/core";

/*
Name	Type	Description
animated	boolean	Determines whether the sections stripes should be animated, if set, striped prop is ignored, false by default
autoContrast	boolean	Determines whether label text color should depend on background-color. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast.
color	MantineColor	Key of theme.colors or any valid CSS value, theme.primaryColor by default
radius	MantineRadius | number	Key of theme.radius or any valid CSS value to set border-radius, theme.defaultRadius by default
size	number | MantineSize | (string & {})	Controls track height, 'md' by default
striped	boolean	Determines whether the section should have stripes, false by default
transitionDuration	number	Controls sections width transition duration, value is specified in ms, 100 by default
value *	number	Value of the progress
*/

registerComponent({
    name: "Progress",
    properties: [
        { name: "value", type: "number", description: "Value of the progress", important: true },
        {
            name: "autoContrast",
            type: "boolean",
            description:
                "Determines whether label text color should depend on background-color. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast.",
            group: "appearance",
        },
        {
            name: "animated",
            type: "boolean",
            description:
                "Determines whether the sections stripes should be animated, if set, striped prop is ignored, false by default",
            group: "progress",
        },
        {
            name: "color",
            type: "color",
            description: "Key of theme.colors or any valid CSS value, theme.primaryColor by default",
            group: "appearance",
        },
        {
            name: "striped",
            type: "boolean",
            description: "Determines whether the sections stripes should be visible, false by default",
            group: "progress",
        },
        {
            name: "transitionDuration",
            type: "number",
            description: "Controls sections width transition duration",
            group: "progress",
        },
        radiusProp,
        sizeProp,
        ...styleProps,
        visibleProp,
    ],
    events: [{ name: "change", defaultEvent: true }],

    component: ({ properties: { visible, value, ...props } }, ref) => {
        props = useChainProps(props, [
            useChainStyleAndClassName(),
            useChainIconProps,
            useChainMarginStyles,
            useChainPaddingStyles,
        ]);
        ref = useVisibleProp(ref, visible);

        return <Progress value={value ?? 0} {...props} ref={ref} />;
    },
});
