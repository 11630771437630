import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import { TEXT_INPUT_EVENTS, useTextInputEvents } from "@/utils/events";
import {
    disabledProp,
    iconProps,
    inputInteractionProps,
    inputPartProps,
    inputPointerProp,
    marginProp,
    placeholderProp,
    radiusProp,
    sizeProp,
    styleProps,
    useInlineEditInputParts,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    visibleProp,
    withErrorStyles,
} from "@/utils/props";
import { useActions } from "@anvil-works/anvil-react";
import { useInlineEditRef } from "@anvil-works/anvil-react/designer";
import { Textarea } from "@mantine/core";
import { defaultInputVariant } from "./pin-input";
import { useWritebackValueOnNativeChange } from "./utils";

registerComponent({
    name: "Textarea",
    events: TEXT_INPUT_EVENTS,
    properties: [
        defaultInputVariant,
        ...inputPartProps,
        {
            name: "autosize",
            type: "boolean",
            description: "Determines whether the textarea height should grow with its content",
            defaultValue: false,
            group: "textarea",
        },
        disabledProp,
        placeholderProp,
        ...iconProps,
        {
            name: "maxRows",
            type: "number",
            description: "Maximum rows for autosize textarea to grow, ignored if autosize prop is not set",
            group: "textarea",
        },
        {
            name: "minRows",
            type: "number",
            description: "Minimum rows of autosize textarea, ignored if autosize prop is not set",
            group: "textarea",
        },
        inputPointerProp,
        radiusProp,
        sizeProp,
        ...inputInteractionProps,
        {
            name: "resize",
            type: "enum",
            options: ["none", "both", "horizontal", "vertical"],
            description: "Controls resize CSS property, 'none' by default",
            defaultValue: "none",
            group: "textarea",
        },
        withErrorStyles,
        {
            name: "value",
            type: "string",
            important: true,
            priority: 10,
            supportsWriteback: true,
            defaultBindingProp: true,
        },
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties }, ref) {
        const { value, iconLeft, iconRight, visible, style, className, margin, ...props } = properties;
        const { setProperty, raiseEvent } = useActions();
        const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = async (e) => {
            const value = e.target.value;
            setProperty("value", value);
        };
        const eventHandlers = useTextInputEvents({ onChange });
        const inputRef = useWritebackValueOnNativeChange<HTMLTextAreaElement>(value);
        ref = useVisibleProp(ref, visible);
        const inputParts = useInlineEditInputParts(props, false);

        return (
            <Textarea
                wrapperProps={{ ref }}
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                value={value ?? ""}
                leftSection={makeIcon(iconLeft)}
                rightSection={makeIcon(iconRight)}
                ref={useInlineEditRef("text", inputRef)}
                {...eventHandlers}
                {...props}
                {...inputParts}
                {...useMarginStyles(margin)}
            />
        );
    },
});
