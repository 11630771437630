import { useMemo, useRef, useState } from "react";
import { STYlE_PROPS_DATA } from "@mantine/core";

export const classProp = {
    name: "className",
    type: "string",
    group: "styles",
} as const;

export const styleProp = {
    name: "style",
    type: "string",
    group: "styles",
} as const;

// not currently used - style prop is probably sufficient
export const stylePropsProp = {
    name: "styleProps",
    type: "string",
    group: "styles",
    description:
        "The same syntax as css inline styles, but supports Mantine Style Props e.g. 'mt:xs; maw:400;'. See Mantine docs for valid style props",
} as const;

function areRecordsEqual(
    record1: React.CSSProperties | null | undefined,
    record2: React.CSSProperties | null | undefined
) {
    if (record1 == null && record2 == null) {
        return true;
    }
    if (record1 == null || record2 == null) return false;
    const keys1 = Object.keys(record1) as (keyof React.CSSProperties)[];
    const keys2 = Object.keys(record2) as (keyof React.CSSProperties)[];

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (record1[key] !== record2[key]) {
            return false;
        }
    }

    return true;
}

export const styleProps = [classProp, styleProp];

export function useStyleObject(
    styleString?: string | null,
    overrides?: React.CSSProperties | null
): React.CSSProperties {
    const [prevOverrides, setPrevOverrides] = useState(overrides);
    if (prevOverrides !== overrides) {
        if (!areRecordsEqual(overrides, prevOverrides)) {
            setPrevOverrides(overrides);
        }
    }

    return useMemo(() => {
        overrides ??= {};
        return Object.assign(
            (styleString || "")
                .split(";")
                .map((style) => style.trim())
                .filter(Boolean)
                .reduce<any>((styles, style) => {
                    let [key, value] = style.split(":");
                    if (key) {
                        key = key.trim().replace(/-([a-z])/g, (match, char) => char.toUpperCase());
                        styles[key] = value?.trim();
                    }
                    return styles;
                }, {}),
            overrides
        );
    }, [styleString, prevOverrides]);
}

export function useStyleProps(stylePropString?: string | null) {
    return useMemo(() => {
        return (stylePropString || "")
            .split(";")
            .map((style) => style.trim())
            .filter(Boolean)
            .reduce<any>((styles, style) => {
                let [key, value] = style.split(":");
                key = key?.trim();
                if (key in STYlE_PROPS_DATA) {
                    styles[key] = value?.trim();
                }
                return styles;
            }, {});
    }, [stylePropString]);
}

export function useStyleAndClassName(
    style?: string,
    className?: string,
    { name = "root", overrides }: { name?: string; overrides?: React.CSSProperties | null } = {}
) {
    return { styles: { [name]: useStyleObject(style, overrides) }, classNames: { [name]: className } };
}
