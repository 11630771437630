import React, { useMemo } from "react";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    styleProp,
    useMarginStyles,
    usePlaceholder,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { inDesigner, useInlineEditRef } from "@anvil-works/anvil-react/designer";
import { Box, TypographyStylesProvider } from "@mantine/core";
import * as DOMPurify from "dompurify";
import { marked } from "marked";
import customHeadingId from "marked-custom-heading-id";

const escapeTest = /[&<>"']/;
const escapeReplace = new RegExp(escapeTest.source, "g");
const escapeTestNoEncode = /[<>"']|&(?!(#\d{1,7}|#[Xx][a-fA-F0-9]{1,6}|\w+);)/;
const escapeReplaceNoEncode = new RegExp(escapeTestNoEncode.source, "g");
const escapeReplacements: { [index: string]: string } = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
};
const getEscapeReplacement = (ch: string) => escapeReplacements[ch];

export function escape(html: string, encode?: boolean) {
    if (encode) {
        if (escapeTest.test(html)) {
            return html.replace(escapeReplace, getEscapeReplacement);
        }
    } else {
        if (escapeTestNoEncode.test(html)) {
            return html.replace(escapeReplaceNoEncode, getEscapeReplacement);
        }
    }

    return html;
}

const renderer = {
    code(code: string, infostring: string | undefined, escaped: boolean): string {
        const lang = (infostring || "").match(/^\S*/)?.[0];
        code = code.replace(/\n$/, "") + "\n";
        if (!lang) {
            return "<pre>" + (escaped ? code : escape(code, true)) + "</pre>\n";
        }
        return '<pre class="language-' + escape(lang) + '">' + (escaped ? code : escape(code, true)) + "</pre>\n";
    },
};

marked.use({ renderer });
marked.use(customHeadingId());

registerComponent({
    name: "RichText",
    properties: [
        { name: "content", type: "string", multiline: true, important: true },
        {
            name: "mode",
            type: "enum",
            options: ["html", "markdown"],
            defaultValue: "markdown",
            important: true,
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    component({ properties }, ref) {
        const { visible, content, style, className, margin, mode } = properties;
        ref = useVisibleProp(ref, visible);
        const html = useMemo(() => {
            let html = content || "";
            // TODO make these lazily loaded
            if (mode !== "html") {
                html = marked.parse(html);
            }
            return DOMPurify.sanitize(html);
        }, [content, mode]);

        const [placeholder, inlineEditOptions, inlineEditing] = usePlaceholder(html);

        const styleObject = useStyleObject(style, inDesigner ? { position: "relative" } : null);
        const marginStyles = useMarginStyles(margin);
        const hiddenStyle: React.CSSProperties = { visibility: "hidden", position: "absolute", inset: 0 };

        return (
            <TypographyStylesProvider>
                <Box ref={ref} style={styleObject} className={className} {...marginStyles}>
                    <div style={inlineEditing ? hiddenStyle : undefined} dangerouslySetInnerHTML={{ __html: html }} />
                    {placeholder}
                    {inDesigner && (
                        <div
                            ref={useInlineEditRef("content", null, inlineEditOptions)}
                            style={inlineEditing ? undefined : hiddenStyle}>
                            {content}
                        </div>
                    )}
                </Box>
            </TypographyStylesProvider>
        );
    },
});
