import { sizes, sizesWithZero } from "./sizing";

export const autoContrast = {
    name: "autoContrast",
    type: "boolean",
    group: "appearance",
    description:
        "Determines whether icon/text color with filled variant should depend on background-color. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast.",
} as const;

export const radiusProp = {
    name: "radius",
    type: "enum",
    options: sizesWithZero,
    defaultValue: "sm",
    group: "appearance",
    description: "Key of theme.radius or any valid CSS value to set border-radius, theme.defaultRadius by default",
} as const;

export const colorProp = {
    name: "color",
    type: "color",
    group: "appearance",
    description: "Key of theme.colors or any valid CSS color, theme.primaryColor by default",
} as const;

export const sizeProp = {
    name: "size",
    type: "enum",
    options: sizes,
    defaultValue: "sm",
    group: "appearance",
    description: "",
} as const;
