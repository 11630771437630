import React from "react";
import { registerComponent } from "@/utils";
import { classProp, marginProp, sizes, styleProp, useVisibleProp, visibleProp } from "@/utils/props";
import {
    useChainHeightWidth,
    useChainMarginStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { heightProp, widthProp } from "@/utils/props/dimensions";
import { inDesigner } from "@anvil-works/anvil-react/designer";
import { ScrollArea } from "@mantine/core";

registerComponent({
    name: "ScrollArea",
    container: true,
    properties: [
        heightProp,
        widthProp,
        // {
        //     name: "offsetScrollbars",
        //     type: "enum",
        //     options: [false, "x", "y"],
        //     description: "Determines whether scrollbars should be offset with padding on given axis, false by default",
        //     defaultValue: false,
        //     group: "appearance",
        // },
        // {
        //     name: "onScrollPositionChange",
        //     type: "((position: { x: number; y: number; }) => void)",
        //     description: "Called with current position (x and y coordinates) when viewport is scrolled",
        //     group: "event",
        // },
        // {
        //     name: "scrollHideDelay",
        //     type: "number",
        //     description:
        //         "Scroll hide delay in ms, applicable only when type is set to hover or scroll, 1000 by default",
        //     defaultValue: 1000,
        //     group: "behavior",
        // },
        // {
        //     name: "scrollbarSize",
        //     type: "string | number",
        //     description:
        //         "Scrollbar size, any valid CSS value for width/height, numbers are converted to rem, default value is 0.75rem",
        //     defaultValue: "0.75rem",
        //     group: "layout",
        // },
        {
            name: "scrollbars",
            type: "enum",
            options: ["none", "x", "y", "xy"],
            description: "Axis at which scrollbars must be rendered, 'xy' by default",
            defaultValue: "xy",
            group: "behavior",
        },
        {
            name: "type",
            type: "enum",
            options: ["auto", "scroll", "always", "hover", "never"],
            description:
                "Defines scrollbars behavior, hover by default - hover – scrollbars are visible when mouse is over the scroll area - scroll – scrollbars are visible when the scroll area is scrolled - always – scrollbars are always visible - never – scrollbars are always hidden - auto – similar to overflow: auto – scrollbars are always visible when the content is overflowing",
            defaultValue: "hover",
            group: "behavior",
        },
        // {
        //     name: "viewportProps",
        //     type: "React.ComponentPropsWithoutRef<'div'>",
        //     description: "Props passed down to the viewport element",
        //     group: "layout",
        // },
        // {
        //     name: "viewportRef",
        //     type: "ForwardedRef<HTMLDivElement>",
        //     description: "Assigns viewport element (scrollable container) ref",
        //     group: "reference",
        // },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    layoutProperties: [],
    autoDropZones: true,
    component({ children, properties: { visible, scrollbars, ...props } }, ref) {
        props = useChainProps(props, [
            useChainHeightWidth,
            useChainMarginStyles,
            useChainStyleAndClassName({ overrides: inDesigner ? { minHeight: 45 } : null }),
        ]);
        ref = useVisibleProp(ref, visible);
        if (scrollbars === "none" || scrollbars === null) {
            scrollbars = false;
        }
        return (
            <ScrollArea ref={ref} scrollbars={scrollbars} {...props}>
                {children}
            </ScrollArea>
        );
    },
});
