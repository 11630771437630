import React from "react";
import { registerComponent } from "@/utils";
import {
    classProp,
    colorProp,
    marginProp,
    override,
    sizeProp,
    sizes,
    styleProp,
    useMarginStyles,
    usePlaceholder,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { useInlineEditRef } from "@anvil-works/anvil-react/designer";
import { Text } from "@mantine/core";

registerComponent({
    name: "Text",
    properties: [
        colorProp,
        {
            name: "inherit",
            type: "boolean",
            description: "Determines whether font properties should be inherited from the parent, false by default",
            defaultValue: false,
            group: "typography",
        },
        {
            name: "inline",
            type: "boolean",
            description: "Sets line-height to 1 for centering, false by default",
            defaultValue: false,
            group: "layout",
        },
        {
            name: "lineClamp",
            type: "number",
            description: "Number of lines after which Text will be truncated",
            group: "typography",
        },
        ...override([sizeProp], {
            size: { description: "Controls font-size and line-height, 'md' by default", defaultValue: "md" },
        }),
        {
            name: "truncate",
            type: "boolean",
            description: "Side on which Text must be truncated, if true, text is truncated from the start",
            group: "typography",
        },
        {
            name: "span",
            type: "boolean",
            description: "whether the component is in a span, otherwise rendered in a p",
            group: "typography",
            defaultValue: true,
        },
        { name: "text", type: "string", defaultValue: "", multiline: true, important: true },
        { name: "bold", type: "boolean", group: "typography", designerHint: "font-bold" },
        { name: "italic", type: "boolean", group: "typography", designerHint: "font-italic" },
        {
            name: "align",
            type: "enum",
            options: ["left", "center", "right"],
            includeNoneOption: true,
            group: "typography",
            designerHint: "align-horizontal",
        },
        visibleProp,
        classProp,
        marginProp,
        styleProp,
    ],
    component({ properties }, ref) {
        const { visible, color, style, className, margin, bold, italic, align, ...props } = properties;
        let text = properties.text;
        ref = useVisibleProp(ref, visible);
        const [placeholder, inlineEditOptions] = usePlaceholder(text);
        if (text != null) {
            text = String(text);
        }
        return (
            <Text
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                ref={useInlineEditRef("text", ref, inlineEditOptions)}
                c={color}
                fw={bold ? "bold" : undefined}
                fs={italic ? "italic" : undefined}
                ta={align}
                {...props}
                {...useMarginStyles(margin)}>
                {text || placeholder}
            </Text>
        );
    },
});
