import { includeContext } from "@anvil-works/anvil-react";
import "@mantine/core/styles.css";
import "./buttons";
import "./combobox";
import "./data-display";
import "./dates";
import "./feedback";
import "./inputs";
import "./layouts";
import "./misc";
import "./navigation";
import "./overlays";
import "./typography";
import { colorSchemeManager, colorSchemeStore } from "./utils/color-scheme";
import { modalStore } from "./utils/modals";
import { WrappedMantineProvider, colorStore } from "./utils/provider";

// @ts-expect-error
window.anvilMantine ??= {};
// @ts-expect-error
Object.assign(window.anvilMantine, { colorSchemeManager, colorSchemeStore, modalStore, colorStore });

includeContext(WrappedMantineProvider);
