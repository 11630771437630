import React from "react";
import { registerComponent } from "@/utils";
import { sizes } from "@/utils/props";
import { useDesignerApi } from "@anvil-works/anvil-react/designer";
import { Indicator } from "@mantine/core";

registerComponent({
    name: "Indicator",
    // events: [{ name: "change", defaultEvent: true }],
    container: true,
    autoDropZones: true,
    properties: [
        {
            name: "autoContrast",
            type: "boolean",
            description:
                "Determines whether text color should depend on background-color. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast.",
            group: "appearance",
        },
        {
            name: "color",
            type: "color",
            description: "Key of theme.colors or any valid CSS color value, theme.primaryColor by default",
            group: "appearance",
        },
        {
            name: "disabled",
            type: "boolean",
            description: "When Indicator is disabled it renders children only",
            group: "interaction",
        },
        {
            name: "inline",
            type: "boolean",
            description: "Determines whether the indicator container should be an inline element, false by default",
            group: "appearance",
        },
        {
            name: "label",
            type: "string",
            description: "Label rendered inside the indicator, for example, notification count",
            important: true,
        },
        {
            name: "offset",
            type: "number",
            description:
                "Indicator offset relative to the target element, usually used for elements with border-radius, equals to size by default",
            group: "appearance",
        },
        {
            name: "position",
            type: "enum",
            options: [
                "bottom-end",
                "bottom-start",
                "top-end",
                "top-start",
                "bottom-center",
                "top-center",
                "middle-center",
                "middle-end",
                "middle-start",
            ],
            description: "Indicator position relative to the target element, 'top-end' by default",
            important: true,
        },
        {
            name: "processing",
            type: "boolean",
            description: "Determines whether the indicator should have processing animation, false by default",
            group: "interaction",
        },
        {
            name: "radius",
            type: "enum",
            options: sizes,
            description: "Key of theme.radius or any valid CSS value to set border-radius, 100 by default",
            group: "appearance",
        },
        {
            name: "size",
            type: "number",
            description: "Indicator width and height, 10 by default",
            group: "appearance",
        },
        {
            name: "withBorder",
            type: "boolean",
            description:
                "Determines whether the indicator should have a border (color of the border is the same as the body element), false by default",
            group: "interaction",
        },
        {
            name: "zIndex",
            type: "number",
            description: "Indicator z-index, 200 by default",
            group: "appearance",
        },
    ],
    component({ properties, children, childrenWithoutDropZones }, ref) {
        let minHeight;
        const { inDesigner } = useDesignerApi();
        if (inDesigner && !childrenWithoutDropZones.length) {
            minHeight = 20;
        }
        return (
            <Indicator style={{ minHeight }} ref={ref} {...properties}>
                {children}
            </Indicator>
        );
    },
});
