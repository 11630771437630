import React from "react";
import { registerComponent } from "@/utils";
import { marginProp, paddingProp, styleProps, useVisibleProp, visibleProp } from "@/utils/props";
import {
    useChainIconProps,
    useChainMarginStyles,
    useChainPaddingStyles,
    useChainProps,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { DropZone, inDesigner } from "@anvil-works/anvil-react/designer";
import { Button } from "@mantine/core";

/*
convert these to properties
Name	Type	Description
borderWidth	string | number	border-width of the child Button components. Numbers are converted to rem. Default value in 1.
children	React.ReactNode	Button components
orientation	"horizontal" | "vertical"	Orientation of the group, horizontal by default
*/

registerComponent({
    name: "ButtonGroup",
    properties: [
        { name: "orientation", type: "enum", options: ["horizontal", "vertical"], defaultValue: "horizontal" },
        {
            name: "borderWidth",
            type: "string",
            description:
                "border-width of the child Button components. Numbers are converted to rem. Default value in 1.",
        },
        ...styleProps,
        marginProp,
        paddingProp,
        visibleProp,
    ],
    autoDropZones: true,
    container: true,
    component: ({ properties: { visible, ...props }, children, childrenWithoutDropZones }, ref) => {
        props = useChainProps(props, [
            useChainStyleAndClassName({ name: "group" }),
            useChainIconProps,
            useChainMarginStyles,
            useChainPaddingStyles,
        ]);
        ref = useVisibleProp(ref, visible);
        const hasChildren = !!childrenWithoutDropZones.length;
        return (
            <Button.Group {...props} ref={ref}>
                {hasChildren ? (
                    children
                ) : inDesigner ? (
                    <div
                        style={{
                            minWidth: 50,
                            minHeight: 45,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}>
                        <DropZone style={{ flexGrow: 1 }} minChildIdx={0} maxChildIdx={0} />
                    </div>
                ) : null}
            </Button.Group>
        );
    },
});
