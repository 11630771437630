import React from "react";
import { makeIcon } from "@/icons";
import { registerComponent } from "@/utils";
import {
    classProp,
    marginProp,
    sizesWithNone,
    styleProp,
    useMarginStyles,
    usePlaceholder,
    useStyleObject,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import { openForm, useActions } from "@anvil-works/anvil-react";
import {
    DropZone,
    inDesigner,
    useDesignerApi,
    useDesignerInteractionRef,
    useDropping,
    useInlineEditRef,
} from "@anvil-works/anvil-react/designer";
import { NavLink } from "@mantine/core";

registerComponent({
    name: "NavLink",
    events: [{ name: "click", defaultEvent: true }],
    container: true,
    autoDropZoneProps: true,
    properties: [
        { name: "variant", type: "enum", options: ["default", "light", "subtle"], defaultValue: "light" },

        {
            name: "active",
            type: "boolean",
            description: "Determines whether the link should have active styles, false by default",
            defaultValue: false,
            group: "appearance",
        },
        {
            name: "autoContrast",
            type: "boolean",
            description:
                "Determines whether button text color with filled variant should depend on background-color. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast.",
            group: "appearance",
        },
        {
            name: "childrenOffset",
            type: "enum",
            options: sizesWithNone,
            description:
                "Key of theme.spacing or any valid CSS value to set collapsed links padding-left, 'lg' by default",
            defaultValue: "lg",
            group: "layout",
        },
        {
            name: "color",
            type: "color",
            description:
                "Key of theme.colors of any valid CSS color to control active styles, theme.primaryColor by default",
            group: "appearance",
        },
        {
            name: "defaultOpened",
            type: "boolean",
            description: "Uncontrolled nested items collapse initial state",
            group: "behavior",
        },
        {
            name: "description",
            type: "string",
            description: "Link description, displayed below the label",
            group: "content",
        },
        {
            name: "disableRightSectionRotation",
            type: "boolean",
            description: "If set, right section will not be rotated when collapse is opened, false by default",
            defaultValue: false,
            group: "behavior",
        },
        {
            name: "disabled",
            type: "boolean",
            description: "If set, disabled styles will be added to the root element, false by default",
            defaultValue: false,
            group: "behavior",
            designerHint: "disabled",
        },
        {
            name: "form",
            type: "form",
            description: "If a form is provided the navlink will call open_form on click",
            important: true,
        },
        {
            name: "href",
            type: "string",
            description: "The URL that the navigation item links to",
            important: true,
            priority: 10,
            defaultBindingProp: true,
        },
        {
            name: "label",
            type: "string",
            description: "Main link label",
            group: "content",
            important: true,
            priority: 10,
        },
        {
            name: "iconLeft",
            type: "icon",
            description: "Section displayed on the left side of the label",
            group: "icon",
        },
        {
            name: "iconRight",
            type: "icon",
            description: "Section displayed on the left side of the label",
            group: "icon",
        },
        {
            name: "noWrap",
            type: "boolean",
            description: "If set, label and description will not wrap to the next line, false by default",
            defaultValue: false,
            group: "appearance",
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    component({ children, childrenWithoutDropZones, properties }, ref) {
        const { href, visible, label, iconLeft, iconRight, style, className, margin, form, ...props } = properties;
        const { raiseEvent } = useActions();
        const dropping = useDropping();
        const onClick = (event: React.MouseEvent) => {
            if (form) {
                openForm(form);
            }
            raiseEvent("click", { preventDefault: () => event.preventDefault(), event });
        };
        ref = useVisibleProp(ref, visible);
        const [placeholder, inlineEditOptions] = usePlaceholder(label);
        // const labelEl = <span>{label || placeholder}</span>;
        const labelEl = <span ref={useInlineEditRef("label", null, inlineEditOptions)}>{label || placeholder}</span>;
        const hasChildren = !!childrenWithoutDropZones.length;

        props.label = labelEl;
        props.onClick = onClick;
        props.leftSection = makeIcon(iconLeft);
        props.rightSection = makeIcon(iconRight);
        let renderChildren: React.ReactNode = hasChildren ? childrenWithoutDropZones : null;
        let opened = inDesigner ? hasChildren : undefined;
        if (inDesigner && dropping) {
            props.disableRightSectionRotation = !hasChildren;
            opened = true;
            renderChildren = hasChildren ? (
                children
            ) : (
                <DropZone style={{ height: 1 }} minChildIdx={0} maxChildIdx={0} />
            );
        }
        const { startEditingForm } = useDesignerApi();
        const interactionRef = useDesignerInteractionRef(
            "dblclick",
            () => {
                form && startEditingForm(form);
            },
            ref,
            { enabled: !!form }
        );

        return (
            <NavLink
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                ref={interactionRef}
                opened={opened}
                component={href ? "a" : "button"}
                href={href || undefined}
                {...props}
                {...useMarginStyles(margin)}>
                {renderChildren}
            </NavLink>
        );
    },
});
